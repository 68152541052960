<template>
  <span class="hovered" ref="popover" triggers="hover" @click="popover.toggle()" :data-bs-placement="position ? position : 'right'" :data-bs-content="content">
    <slot></slot>
  </span>
</template>

<script>
import { Popover } from "@comlinedev/bootstrap5"

export default {
  name: 'popover',

  data(){
    return{
      popover: null
    }
  },
  props:{
    content: String,
    position: String
  },
  mounted(){
    this.popover = new Popover(this.$refs.popover, {
      trigger: 'focus'
    })
  },
  beforeUnmount(){
    this.popover.hide()
  }
}
</script>

<style scoped>
  .hovered {
    cursor: pointer;
  }
</style>
