<template>
  <div id="selectMailing">
    <Loading v-if="working" />
    <template v-else>
      <input type="text" autocomplete="false" class="form-control mb-1" id="search" placeholder="Suchen" v-model="search">
      <div class="scrollBox">
        <table class="table mb-0">
          <tbody>
            <tr v-for="(data, index) in tableDataFiltered" :key="index" :class="use === data.name && 'table-light'">
              <td>{{data.name}}</td>
              <td>
                <div class="form-check form-switch d-flex justify-content-end">
                  <input class="form-check-input" type="radio" :id="data.name" :value="data.name" v-model="use">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from "../Loading.vue"
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  name: 'selectMailing',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  components:{
    Loading
  },
  props: {
    preSelected: String
  },
  watch: {
    preSelected: {
      handler: function(val) {
        this.use = val
      }
    },
    use: {
      handler: function() {
        // console.log(this.use)
        this.$emit("selectMailingChanged", this.use)
        return null;
      },
      deep: true
    },
  },
  computed:{
    tableDataFiltered(){
      return this.list.filter(data => {
        return data.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  emits: ['selectMailingChanged'],
  data(){
    return{
      working: true,
      list: false,
      use: null,
      search: '',
      retry: {
        max: 4,
        trys: 0
      }
    }
  },
  methods:{
    async loadData(){ 
      this.retry.trys ++

      // console.log('selectmailing',this.region)
      const getTemplates = httpsCallable(getFunctions(this.firebase, this.region), 'getTemplate');
      await getTemplates()
      .then(res=>{
        this.list = res.data
        this.working = false
        // console.log('this ist preselected', this.preSelected)
      })
      .catch(e=>{
        if(this.retry.trys <= 4) {this.loadData()}
        console.error(e)
        this.working = false
        this.error = true
      })
    }
  },
  async mounted(){
    await this.loadData()
  },
}
</script>

<style>
  #card {
    cursor: pointer;
  }
  .scrollBox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
    max-height: 300px; 
    overflow-y: scroll;
  }
</style>
