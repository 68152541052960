<template>
  <div class="col text-left">
    <h3>Grundlagen</h3>
    <p></p>
    <br>
    <h4>Abonentenanmeldung</h4>
    <p>Grundsätzlich ist es vorgesehen, dass sich Abonenten selbsständig in den Newsletter eintragen.</p>
    <a href="https://shop.comline-shop.de/static/einstellungen.htm" alt="Anmeldung">Anmeldung für Abonenten</a>
    <p>Beim Aufrufen wird die Sprache anhand der Shopein stellungen automatisch gesetzt. Verfügbare Sprachen sind Deutsch, Englich und Französisch, je nach Shop</p>
    <br>
    
    <h4>Preference Center</h4>
    <p>Über das Preference Center können Abonenten ihre E-Mail-Adresse ändern, die Gewünschten Verteiler ändern, oder sich von allen Newslettern abmelden und ihre Daten aus der Datenbank und dem deLUXElöschen.</p>
    <p>Der Personalisierte Link zu dem Preference Center <b>muss</b> in jedem Mailing über <code>%preference-center%</code> eingebunden werden.</p>
    <p>Die Links in den Mailings sind maximal ein Jahr gültig um Brutforceangriffe zu erschweren.</p>
    <br>

    <h4>Blocks</h4>
    <p>Abonenten von denen eine Block benachrichtigung zurück kommt, werden dauerhaft aus der Datenbank und dem deLUXE entfernt, und werden wie abgemeldete Abonenten behandelt.</p>
    <p>Dies ist ein Sicherheitsmechanismus. Würden wir weiterhin an diese E-Mail-Adressen senden, wird unsere IP-Adresse abgewertet und und wir werden von mehr Servern als Spam eingeordnet.</p>
    <p>Nur der Abonenten selber kann sich durch erneutes anmelden und bestätigen der E-Mail-Adresse wieder für den Newsletter anmelden.</p>
    <br>

    <h4>Bounces</h4>
    <p>Abonenten von denen eine Hardbouncebenachrichtigung zurück kommt, werden dauerhaft aus der Datenbank und dem deLUXE entfernt, und werden wie abgemeldete Abonenten behandelt.</p>
    <p>Abonenten von denen eine Softbouncebenachrichtigung zurück kommt, werden für eine Woche gesperrt und anschließend wieder aktiviert.</p>
    <p>Erhalten wir nach dem reaktivieren erneut eine Softbouncebenachrichtigung wird dieser Abonenten dauerhaft gesperrt und wie abgemeldete Abunenten behandelt.</p>
    <p>Dies ist ein Sicherheitsmechanismus. Würden wir weiterhin an diese E-Mail-Adressen senden, wird unsere IP-Adresse abgewertet und und wir werden von mehr Servern als Spam eingeordnet.</p>
    <p>Nur der Abonenten selber kann sich durch erneutes anmelden und bestätigen der E-Mail-Adresse wieder aktivieren.</p>
  </div>
</template>

<script>
export default {
  name: 'dkouGeneral'
}
</script>

<style scoped>
  code {
    border: 1px #ced4da solid;
    border-radius: .25rem;
    padding: 5px;
  }
</style>