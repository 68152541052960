<template>
  <div class="">
    <h5 class="text-center mt-5 mb-5">Verteiler bearbeiten</h5>
    <transition name="fade">
      <Loading v-if="working" />
    </transition>
    <transition name="fade">
      <div class="container" v-if="!working" >
        <div class="row">
          <div class="col">

            <form class="row g-3" @submit.prevent="checkForm">
              <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" placeholder="verteilername" v-model="name">
              </div>

              <div class="mb-3">
                <label for="description" class="form-label">Beschreibung</label>
                <textarea class="form-control" id="description" rows="3" v-model="description"></textarea>
              </div>

              <label class="form-label mt-3">Empfänger</label>
              <div class="d-flex align-items-center" v-for="address, index in addedMailAddresses" :key="index">
                <input type="email" class="form-control mt-1" placeholder="E-Mail-Adresse" v-model="addedMailAddresses[index]['email']" aria-label="Mail">
                <input type="text" class="form-control mt-1 ms-2" placeholder="Kundennummer" v-model="addedMailAddresses[index]['customernumber']" aria-label="Kundennummer">
                <button type="button" class="btn" @click="addedMailAddresses.length > 1 && addedMailAddresses.splice(index, 1);"><font-awesome-icon :icon="['fas', 'times']" /></button>
              </div>
              <div>
                <button type="button" class="btn" @click="addedMailAddresses.push({email: '', customernumber: ''})"><font-awesome-icon :icon="['fas', 'plus']" /> Weiterer Empfänger</button>
              </div>

              <h3>Testdaten</h3>
              <div class="mb-3">              
                <label for="salutation" class="form-label">Anrede</label>
                <select class="form-select" id="salutation" v-model="dummyData.salutation">
                  <option selected disabled>Bitte wählen</option>
                  <option value="herr">Herr</option>
                  <option value="frau">Frau</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="name" class="form-label">Vorname</label>
                <input type="text" class="form-control" id="firstname" placeholder="Vorname" v-model="dummyData.firstname">
              </div>

              <div class="mb-3">
                <label for="lastname" class="form-label">Nachname</label>
                <input type="text" class="form-control" id="lastname" placeholder="Nachname" v-model="dummyData.lastname">
              </div>

              <div class="mb-3">
                <label for="company" class="form-label">Firma</label>
                <input type="text" class="form-control" id="company" placeholder="Firma" v-model="dummyData.company">
              </div>

              <transition name="fade">
                <div  v-if="errors.length > 0" class="mb-3">
                  <div class="alert alert-danger" role="alert">
                    <ul>
                      <li v-for="error in errors" :key="error">{{error}}</li>
                    </ul>
                  </div>
                </div>
              </transition>

              <div class="col d-flex justify-content-center mb-5">
                <SaveButton :saving="saving" text="Speichern" :done="done"/>
              </div>
            </form>

          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getFirestore, updateDoc, getDoc, doc } from "firebase/firestore"; 
import Loading from "../Loading.vue"
import SaveButton from "../smallComponents/SaveButton.vue"

export default {
  name: 'EditDistributor',
  components:{
    Loading,
    SaveButton
  },
  watch: {
    isPublic: function (val) {
      val && (this.selectedUser = [])
    },
    name: function (val) {
      if(val){
        let index = this.name.indexOf(this.erroMessages.name)
        if (index > -1) {
          this.errors.splice(index, 1);
        }
      }
    },
    description: function (val) {
      if(val){
        let index = this.description.indexOf(this.erroMessages.description)
        if (index > -1) {
          this.errors.splice(index, 1);
        }
      }
    },
  },
  data(){
    return {
      name: null,
      description: null,
      dummyData: {
        salutation: null,
        firstname: null, 
        lastname: null,
        company: null
      }, 
      addedMailAddresses: [{email: '', customernumber: ''}],
      errors: [],
      erroMessages: {
        name: 'Bitte fülle das Feld Verteilername aus.',
        description: 'Bitte trage eine Beschreibung ein.',
        email: 'Eins oder mehr Felder sind nicht ausgefüllt.',
        firstname: 'Bitte fülle den Vorname aus.',
        lastname: 'Bitte fülle den Nachnamen aus.',
        salutation: 'Bitte wähle eine Anrede.',
        company: 'Bitte fülle den Firmennamen aus.'
      },
      working: true,
      saving: false,
      done: false,
    }
  },
  methods:{
    checkForm(e){
      e.preventDefault();
        this.errors = []
        !this.name && this.errors.push(this.erroMessages.name)
        !this.description && this.errors.push(this.erroMessages.description)
        !this.dummyData.salutation && this.errors.push(this.erroMessages.salutation)
        !this.dummyData.firstname && this.errors.push(this.erroMessages.firstname)
        !this.dummyData.lastname && this.errors.push(this.erroMessages.lastname)
        !this.dummyData.company && this.errors.push(this.erroMessages.company)
        let emailError = false
        this.addedMailAddresses.forEach(each=>{
          each.email == '' && (emailError = true)
          each.customernumber == '' && (emailError = true)
        })
        emailError && this.errors.push(this.erroMessages.email)
      if(this.errors.length <= 0) {
        this.saveData()
      }
    },
    async saveData(){

      this.saving = true
      let data = 
        {
          name: this.name,
          description: this.description,
          addresses: this.addedMailAddresses,
          personData: this.dummyData
        }

      await updateDoc(doc(getFirestore(), "testDistributor", this.$route.params.id), data);
      this.saving = false
      this.done = true
      setTimeout(()=>{this.$router.replace('/distributor/listtest')}, 2000);
    },
    async loadData(){
      const distributorSnap = await getDoc(doc(getFirestore(), "testDistributor", this.$route.params.id ));
      if (distributorSnap.exists()) {
        const loadedData = distributorSnap.data()
        this.name = loadedData.name
        this.description = loadedData.description
        this.addedMailAddresses = loadedData.addresses
        this.dummyData = loadedData.personData
      }
    }
  },
  async mounted(){
    await this.loadData()
    this.working = false
  }
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
  .fade-enter-active,
  .fade-leave-active {
    transform: scaleY(1);
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.25s ease;
  }
</style>