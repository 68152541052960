<template>
  <BreadCrumb/>
  <OverView/>
  <div class="container mt-3">
    <h3 class="mb-3 text-center">Mailing Versand</h3>
    <Loading v-if="working"/>
    <div v-else-if="!done">
      <!-- <div class="mt-3 mb-3">
        <h5>{{mailing.name}}</h5>

        <p v-if="mailing.description">Beschreibung: {{mailing.description}}</p>
        <p>Template: <b>{{mailing.mailing}}</b></p>
        <p v-if="mailing.articleNumbers.length > 0 && mailing.priceImport">Preisimport:
          <template v-if="mailing.priceImport.state === 'done'"><b>{{mailing.priceImport.date.toDate().toLocaleDateString('de-DE')}}</b> {{mailing.priceImport.date.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})}} Uhr </template>
          <template v-else-if="!mailing.priceImport"><font-awesome-icon :icon="['fas', 'times']" color="red;"/> Fehlt</template>
        </p>
        <template  v-if="distributors.length > 0">
          <template v-for="(distributor, index) in distributors" :key="index">
          <p>Verteiler-<b>{{index+1}}</b>: <b>{{distributor.name}}</b></p>
          <p>Aktive Abonenten: <b>{{distributor.selectedUser.length}}</b></p>
          </template>
          <template  v-if="distributors.length > 1">
            <p>Abonenten bereinigt: {{Abonentenbereinigt(distributors)}}</p>
          </template>
        </template>
      </div>
      <hr> -->
      <div class="mb-3">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="isPublic" v-model="isTimed">
          <label class="form-check-label" for="isPublic">Terminierter Versand</label>
        </div>
      </div>

      <div class="mb-3" v-if="isTimed">
        <label class="form-check-label mb-2">Versandzeitpunkt</label>
        <Datepicker v-model="date" locale="de" cancelText="abbrechen" selectText="auswählen"  :minDate="new Date()" :minTime="new Date()" />
      </div>

      <!-- <select class="form-select" aria-label="Default select example" @change="setSender($event)">
        <option selected disabled>Wähle einen Absender</option>
        <option v-for="(sender, index) in senders" :key="index" :value="index">{{sender.name}}</option>
      </select>

      <div class="mb-3">
        <label for="senderName" class="form-label">Absender Name</label>
        <input type="text" class="form-control" placeholder="Absender" v-model="sender.name" aria-label="senderName">
      </div>

      <div class="mb-3">
        <label for="senderEmail" class="form-label">Absender E-Mail-Adresse</label>
        <input type="email" class="form-control" placeholder="E-Mail-Adresse" v-model="sender.email" aria-label="senderEmail">
      </div> -->

      <transition name="fade">
        <div  v-if="errors.length > 0" class="mb-3">
          <div class="alert alert-danger" role="alert">
            <ul>
              <li v-for="error in errors" :key="error">{{error}}</li>
            </ul>
          </div>
        </div>
      </transition>

      <div class="mt-5 text-center" v-if="!mailing.status">
        <button class="btn btn-primary" @click="sendMail()"><font-awesome-icon :icon="['fas', 'paper-plane']" /> Absenden</button>
      </div>
      <div  v-else class="alert alert-danger">Dieses Mailing wurde bereits versendet oder es wurde ein Terminierter Versand festgelegt.</div>
    </div>
    <div  v-else class="alert alert-success">Das Mailing wurde für den Versand vorbereitet.</div>
  </div>
</template>

<script>
import Loading from '../Loading.vue'
import BreadCrumb from '../smallComponents/MailBreadCrumb.vue'
import { mapGetters } from "vuex";
import { getFirestore, doc, getDoc, setDoc, updateDoc, collection, query, getDocs } from "firebase/firestore";
import Datepicker from "@vuepic/vue-datepicker";
import OverView from '../smallComponents/MailOverview.vue'
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'TestDelivery',
  components:{
    Loading,
    Datepicker,
    BreadCrumb,
    OverView
  },
  data(){
    return {
      working: true,
      mailing: null,
      date: null,
      isTimed: false,
      sender: {email: "marketing@comline-shop.de", name: "ComLine GmbH"},
      distibutor: null,
      done: false,
      errors: [],
      senders: [],
      selected: null,
      distributors: []
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings",
    })
  },
  methods:{
    async loadData(){
      await this.getMailingData()
      await this.getSender()
      this.working = false
    },
    async getMailingData(){
      const docSnap = await getDoc(doc(getFirestore(), "mailing", this.$route.params.id ));

      if (docSnap.exists()) {
        // console.log(docSnap.data())
        this.mailing = docSnap.data()
      } else {
        this.errors.push('mailing')
        console.error("No such document!");
      }
    },
    async getSender(){
      const q = query(collection(getFirestore(), "sender"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.senders.push(doc.data())
      })
    },
    setSender(event){
      this.sender = this.senders[event.target.value]
    },
    async sendMail(){
      let checked = this.checkData()
      if(checked.length <= 0){
        // console.log(this.selectedTestDistibutor)
        this.working = true;
        let objectToSave = {
          isTimed: true,
          date: this.isTimed ? this.date : new Date(),
          uid: this.$store.state.user.data.uid
        }
        await setDoc(doc(getFirestore(), "sendMailing", this.$route.params.id), objectToSave)
              this.done = true
      setTimeout(()=>{this.$router.push('/admin')}, 2000);
        this.working = false
        // console.log(objectToSave) 
        
        await updateDoc(doc(getFirestore(), "mailing", this.$route.params.id ),{'status': 'initialized'})
      } else {
        console.error('Bitte prüfe deine Eingaben.')
      }
    },
    Abonentenbereinigt(distributors){
      let subscribers = []
      distributors.forEach(el=>{subscribers = [...subscribers, ...el.selectedUser]})
      return subscribers.length
    },
    checkData(){
      let check = []
      this.mailing.articleNumbers && this.mailing.articleNumbers.length > 0 && !this.mailing.priceImport && (check.push('Die Preise wurden nicht Importiert.')) &&
      this.mailing.priceImport.state !== 'done' && (check.push('Der Preisimport ist nicht abgeschlossen.'))
      this.isTimed && !this.date && (check.push('Es wurde kein Zeitpunkt für den Versand festgelegt.'))

      this.errors = check
      return check
    },
  },
  mounted(){
    this.loadData()
  },
}
</script>

<style scoped>
  .scrollBox {
    border: 1px #f2f2f2 solid;
    max-height: 900px; 
    overflow-y: scroll;
  }
</style>