<template>
  <span style="color:green;"  v-if="check == true"><font-awesome-icon :icon="['fas', 'check']" /></span>
  <span style="color:red;" v-else-if="check == false"><font-awesome-icon :icon="['fas', 'times']" /></span>
  <Loading v-else/>
</template>

<script>
import Loading from '../Loading.vue'

export default {
  name: 'Check',
  components:{
    Loading
  },
  props:{
    check: Boolean
  },
}
</script>

