<template>
  <div>
      <div class="container text-center" style="height: calc(100vh - 49px);">
        <div class="row align-items-center" style="height: 100%;">
          <div class="col">
            <router-link to="/mailing/create">
              <Card name="Mailing erstellen" :icon="['fas', 'plus']"/>
            </router-link>
          </div>
          <div class="col">
            <router-link to="/mailing/list">
              <Card name="Mailings" :icon="['fas', 'list']"/>
            </router-link>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Card from '../smallComponents/Card'

export default {
  name: 'FirstSite',
  components:{
    Card,
  },
  computed: {

  },
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
</style>