<template>
  <div id="card">
    <div class="card text-center">
      <div class="d-flex justify-content-center m-5">
        <font-awesome-icon :icon="icon" size="7x" />
      </div>
      <hr>
      <div class="card-body">
        <h5 class="card-title">{{name}}</h5>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'card',

  data(){
    return{

    }
  },
  props:{
    name: String,
    icon: Array,
  },
  methods:{

  },
  mounted(){

  },
}
</script>

<style>
  #card {
    cursor: pointer;
  }
</style>
