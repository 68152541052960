<template>
  <div class="container">
    <div class="row justify-content-center  mb-5 ">
      <h5 class="text-center mb-5 mt-5">{{$t('message.preferencecenter.title')}}</h5>
      <Loading v-if="working"/>
      <div class="col-md-6" v-if="!working">
        <form class="row g-3" @submit.prevent="checkForm">
          <template v-if="!showDelteSuccess">
            <!-- <div class="mb-3">              
              <label for="salutation" class="form-label">{{$t('message.preferencecenter.salutation')}}</label>
              <select class="form-select" id="salutation" v-model="salutation">
                <option disabled selected>{{$t('message.preferencecenter.selectsalutation')}}</option>
                <option value="herr">{{$t('message.preferencecenter.mr')}}</option>
                <option value="frau">{{$t('message.preferencecenter.mrs')}}</option>
              </select>
            </div> -->

            <!-- <div class="mb-3">
              <label for="firstname" class="form-label">{{$t('message.preferencecenter.firstname')}}</label>
              <input type="firstname" class="form-control" id="firstname" :placeholder="$t('message.preferencecenter.firstname')" v-model="firstname">
            </div>

            <div class="mb-3">
              <label for="lastname" class="form-label">{{$t('message.preferencecenter.lastname')}}</label>
              <input type="lastname" class="form-control" id="lastname" :placeholder="$t('message.preferencecenter.lastname')" v-model="lastname">
            </div> -->

            <div class="mb-3">
              <label for="customernumber" class="form-label">{{$t('message.preferencecenter.customernumber')}}</label>
              <input type="customernumber" disabled class="form-control" id="customernumber" :placeholder="$t('message.preferencecenter.customernumber')" v-model="customernumber">
            </div>

            <!-- <div class="mb-3">
              <label for="company" class="form-label">{{$t('message.preferencecenter.company')}}</label>
              <input type="company" class="form-control" id="company" :placeholder="$t('message.preferencecenter.company')" v-model="company">
            </div> -->

            <div class="mb-5">
              <label for="email" class="form-label">{{$t('message.preferencecenter.email')}}</label>
              <input type="email" class="form-control" id="email" :placeholder="$t('message.preferencecenter.email')" v-model="email">
            </div>

            <div class="mb-3" >
              <p class="mb-0">{{$t('message.preferencecenter.mydistributors')}}</p>
              <ul class="list-group">
                <li class="list-group-item" v-for="(category, index) in allCategories" :key="index">
                  <div class="form-check form-switch">
                    <label class="clickable" :for="category.name">{{categoryNames?.[category.name]?.[this.$route.params.lang] || category.name}}</label>
                    <input class="form-check-input" type="checkbox" :id="category.name" :value="category.name" v-model="selected">
                  </div>
                </li>
              </ul>
            </div>

            <transition name="fade">
              <div  v-if="errors.length > 0" class="mb-3">
                <div class="alert alert-danger" role="alert">
                  <ul>
                    <li v-for="error in errors" :key="error">{{error}}</li>
                  </ul>
                </div>
              </div>
            </transition>
          </template>

          <transition name="fade">
            <div  v-if="showDelteSuccess" class="mb-3">
              <div class="alert alert-success" role="alert">
                <p>{{$t('message.preferencecenter.deleteSuccess')}}</p>
              </div>
            </div>
          </transition>

          <template v-if="!showDelteSuccess">
            <div class="mt-3 text-center">
              <SaveButton :saving="saving" :text="$t('message.signup.save')" :done="done"/>
            </div>
          </template>
        </form>
      </div>
      <div class="text-center mt-5" v-if="!showDelteSuccess">
        <a href="#" class="link-secondary" @click="modal.show()">{{$t('message.preferencecenter.deleteAllData')}}</a>
      </div>
    </div>
  </div>

  <div class="modal" tabindex="-1" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('message.preferencecenter.deleteModalHeadline')}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{$t('message.preferencecenter.deleteModalText')}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('message.preferencecenter.quitButton')}}</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="color: white;" @click="deleteMe()">{{$t('message.preferencecenter.deleteButton')}}</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import Loading from "../Loading.vue"
import SaveButton from "../smallComponents/SaveButton.vue"
import { Modal } from "@comlinedev/bootstrap5"
import EmailValidator from 'email-validator';

export default {
  name: 'PreferenceCenter',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  components:{
    Loading,
    SaveButton
  },
  watch: {
    email: function (val) {
      if(val){
        let index = this.errors.indexOf(this.erroMessages.email)
        if (index > -1) {
          this.errors.splice(index, 1);
        }
      }
    },
    // salutation: function (val) {
    //   if(val){
    //     let index = this.errors.indexOf(this.erroMessages.salutation)
    //     if (index > -1) {
    //       this.errors.splice(index, 1);
    //     }
    //   }
    // },
    // lastname: function (val) {
    //   if(val){
    //     let index = this.errors.indexOf(this.erroMessages.lastname)
    //     if (index > -1) {
    //       this.errors.splice(index, 1);
    //     }
    //   }
    // },
    // firstname: function (val) {
    //   if(val){
    //     let index = this.errors.indexOf(this.erroMessages.firstname)
    //     if (index > -1) {
    //       this.errors.splice(index, 1);
    //     }
    //   }
    // },
    customernumber: function (val) {
      if(val){
        let index = this.errors.indexOf(this.erroMessages.customernumber)
        if (index > -1) {
          this.errors.splice(index, 1);
        }
      }
    },
    // company: function (val) {
    //   if(val){
    //     let index = this.errors.indexOf(this.erroMessages.company)
    //     if (index > -1) {
    //       this.errors.splice(index, 1);
    //     }
    //   }
    // }
  },
  data(){
    return {
      email: "",
      lastname: null,
      firstname: null,
      customernumber: "",
      salutation: undefined,
      company: null,
      errors: [],
      erroMessages: {
        email: this.$t('message.preferencecenter.errors.email'),
        // salutation: this.$t('message.preferencecenter.errors.salutation'),
        // firstname: this.$t('message.preferencecenter.errors.firstname'),
        // lastname: this.$t('message.preferencecenter.errors.lastname'),
        customernumber: this.$t('preferencecenter.signup.errors.customernumber'),
        // company: this.$t('message.preferencecenter.errors.company')
      },
      working: true,
      saving: false,
      done: false,
      selected: [],
      allCategories: null,
      categoryNames: null,
      modal: null,
      showDelteSuccess: false
    }
  },
  methods:{
    checkForm(e){
      e.preventDefault();
      if(this.email && this.customernumber && EmailValidator.validate(this.email)) {
        this.saveData()
      }
      else {
        this.errors = []
        !EmailValidator.validate(this.email) && this.errors.push('email')
        !this.email && this.errors.push(this.erroMessages.email)
        // !this.salutation && this.errors.push(this.erroMessages.salutation)
        // !this.firstname && this.errors.push(this.erroMessages.firstname)
        // !this.lastname && this.errors.push(this.erroMessages.lastname)
        !this.customernumber && this.errors.push(this.erroMessages.customernumber)
        // !this.company && this.errors.push(this.erroMessages.company)
      }
    },
    async saveData(){
      this.saving = true
      let saveMyData = await httpsCallable(getFunctions(this.firebase, this.region), 'setPreferences');
      await saveMyData({
        id: this.$route.params.id, 
        token: this.$route.params.token,
        email: this.email,
        selected: this.selected,
        lang: this.$route.params.lang
      })
      .then(()=>{
        this.saving = false
        this.done = true
      setTimeout(()=>{this.done = false}, 2000);
      })
    },
    async loadMyData(){
      let getMyData = await httpsCallable(getFunctions(this.firebase, this.region), 'getMyData');
      await getMyData({id: this.$route.params.id, token: this.$route.params.token})
      .then((data)=>{
        console.log("!!!!!",data)
        const person = data.data
        this.email = person.email
        this.customernumber = person.customerNumber,
        this.personId = person.personId
        this.company = person.company


        person?.personKeywords && person.personKeywords.forEach(el=>{
          this.selected.push(el)
        })
      })
      .catch(err=>{
        console.error(err)
        this.errors.push('Ihnen Fehlt die Berechtigung.')
      })
    },
    async distributorRequest(){
      let getCategories = await httpsCallable(getFunctions(this.firebase, this.region), 'getPersonKeywords');
      let getCategorieTranslations = await httpsCallable(getFunctions(this.firebase, this.region), 'getPersonKeywordTranslations');
      await getCategories({id: this.$route.params.id, token: this.$route.params.token})
      .then((data)=>{
        console.log(data.data)
        this.allCategories = []
        data.data.forEach((el, index)=>this.allCategories.push({name:el, id:index}))
      })
      .catch(err=>{      
        console.error(err)
        this.errors.push('Ihnen Fehlt die Berechtigung.')
      })

      await getCategorieTranslations()
      .then((data)=>{
        console.log('here', data.data)
        this.categoryNames = data.data
      })
      .catch(err=>{      
        console.error(err)
        this.errors.push('Es ist ein Fehler aufgetreten!')
      })
      this.allCategories.forEach(el=> el.id = this.categoryNames[el.name].id)
      this.allCategories.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))

    },
    async deleteMe(){
      let deleteMe = await httpsCallable(getFunctions(this.firebase, this.region), 'deleteMe');
      await deleteMe({id: this.$route.params.id, token: this.$route.params.token})
      .then(()=>{
        this.showDelteSuccess = true
        this.email = null
        this.lastname = null
        this.firstname = null
        this.customernumber = null
        this.salutation = undefined
        this.company = null
        this.selected = []
      })
      .catch(err=>{
        console.error(err)
      })
    }
  },
  async mounted(){
    await this.loadMyData()
    await this.distributorRequest()
    this.modal = new Modal(this.$refs.modal)
    this.working = false
  }
}
</script>

<style scoped>
  a {
    text-decoration: none;
  }
  .fade-enter-active,
  .fade-leave-active {
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.25s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.25s ease;
  }

  .clickable {
    cursor: pointer;
  }
</style>