<template>
  <div class="text-left">
    <h5 class="mb-5 mt-5 text-center" v-if="data">{{data.name}}</h5>
    <div class="container">
      <div class="m-1">
        <div class="row">
          <div class="col-12 d-flex justify-content-between">
            <div class="d-flex justify-content-between">
              <button class="btn btn-danger" @click="stopTimedDelivery()" v-if="dataSending && dataSending?.status !== 'stop'">Versand anhalten</button>
              <div><SaveButton @click="reloadMailData()" v-if="dataSending && dataSending?.status === 'stop'" :saving="loadingNewData" text="HTML & TEXT neu vom Server laden" :done="loadingNewDataDone"/></div>
              <button class="btn btn-success ms-2" @click="startTimedDelivery()" :disabled="loadingNewData" v-if="dataSending && dataSending?.status === 'stop'">Versand wieder starten</button>
            </div>
            <DropDown :button="true" :id="this.$route.params.id">Menu</DropDown>
          </div>
        </div>
        <div class="alert alert-danger mt-2" v-if="dataSending && dataSending?.status === 'stop'"> Der Versand wurde angehalten. Du kannst den Versand nun wieder starten oder die Daten neu laden. Es werden nur die HTML und TXT datein neu geladen. Wenn sich links geändert haben werden diese aktualisiert. Achtung! Änderungen an Artikelnummern können wegen des Preisimports nicht mehr berücksichtigt werden!</div>
      </div>
      <div class="row" v-show="show === 'Link'">
        <div class="col">
          <div class="custombox p-3 m-1" v-if="data">
            <div class="row text-center">
              <div class="col-12" v-if="data.description">
                <p>{{data.description}}</p>
              </div>
               <div class="col-4" v-if="data.sendTestmail">
                <font-awesome-icon :icon="['fas', 'paper-plane']" size="2x" class=""/>
                <p>Letzter Testversand: <br>{{data.sendTestmail.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })}} {{data.sendTestmail.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})}} Uhr  </p>
              </div>
              <div class="col-4" v-if="views">
                <font-awesome-icon :icon="['fas', 'eye']" size="2x" class=""/>
                <p>Testversand Ansichten: <br>{{views}}</p>
              </div>
              <div class="col-4" v-if="data.priceImport">
                <font-awesome-icon :icon="['fas', 'file-import']" size="2x" class=""/>
                <p>Letzter Preisimport<br> {{data.priceImport.date.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })}} {{data.priceImport.date.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})}} Uhr  </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col mb-3">
          <div class="custombox p-3 m-1" >
            <div>
              <iframe 
                class="iframe"
                title="Mailing"
                :src="iframeSrc">
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getFirestore, getDoc, doc, collection, getDocs, addDoc, updateDoc} from "firebase/firestore"; 
import { getFunctions, httpsCallable } from "firebase/functions";
import { mapGetters } from "vuex";
import DropDown from "../smallComponents/MailDropDownMenu.vue"
import SaveButton from "../smallComponents/SaveButton.vue"

export default {
  name: 'Mailinfo',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  components: {
    DropDown,
    SaveButton
  },
  computed: {
    ...mapGetters({
      settings: "settings",
    })
  },
  data(){
    return{
      working: true,
      show: 'Link',
      data: null,
      dataSending: null,
      clicks: 0,
      views: 0,
      iframeSrc: null,
      loadingNewDataDone: false,
      loadingNewData: false
    }
  },
  methods:{
    async mailLog(action){
      const ref = await collection(getFirestore(), "mailing", this.$route.params.id, "log");
      await addDoc(ref, {action: action, time: new Date()})
    },
    async stopTimedDelivery(){
      const docRef = await doc(getFirestore(), "sendMailing", this.$route.params.id);
      await updateDoc(docRef, {status: 'stop'})
      .then(()=>{
        this.loadData()
        this.mailLog('Mailversand wurde angehalten.')
      })
    },
    async startTimedDelivery(){
      const docRef = await doc(getFirestore(), "sendMailing", this.$route.params.id);
      await updateDoc(docRef, {status: 'restart'})
      .then(()=>{
        this.loadData()
        this.mailLog('Mailversand wurde wieder gestartet.')
      })
    },
    async reloadMailData(){
      this.loadingNewData= true
      this.mailLog('Neue HTML und TXT Daten wurden geladen.')
      const reloadHtml = await httpsCallable(getFunctions(this.firebase, this.region), 'reloadHTML');
      await reloadHtml({mailingId: this.$route.params.id})
      .then(()=>{
      this.loadingNewData= false
      this.loadingNewDataDone = true
      setTimeout(()=>{this.loadingNewDataDone = false}, 2000);
      })
    },
    async loadData(){
      const docRefSending = await doc(getFirestore(), "sendMailing", this.$route.params.id);
      const docRef = await doc(getFirestore(), "mailing", this.$route.params.id);
      const snap = await getDoc(docRef);
      const data = snap.data()
      this.data = data
      this.iframeSrc = this.settings.webserver + data.mailing + '/index.htm'
      const snapSending = await getDoc(docRefSending);
      this.dataSending = snapSending.data()
      return 'ok'
    },
    async getViews(){
      const ref = await collection(getFirestore(), "mailing", this.$route.params.id, "viewstest");
      getDocs(ref)
      .then(res=>{
        this.views = res.docs.length
      })
    },
    async getClicks(){
      const ref = await collection(getFirestore(), "mailing", this.$route.params.id, "trackingTestmail");
      getDocs(ref)
      .then(res=>{
        this.clicks = res.docs.length
      })
    },
    async dublicate(id){
      await getDoc(doc(getFirestore(), "mailing", id ))
      .then(async snap =>{

        const oldData = snap.data()
        let newData = {}
        newData.name = 'Kopie - ' + oldData.name
        newData.timestamp = new Date()
        newData.priceImport = false
        newData.mailing = oldData.mailing
        newData.sender = oldData.sender

        await addDoc(collection(getFirestore(), "mailing"), newData)
        .then((a)=>{
          this.mailLog('Mailing wurde dubliziert.')
          this.$router.push(`/mailing/general/${a.id}`)
        })

      })
    },
  },
  async mounted(){
    await this.loadData()
    await this.getViews()
    await this.getClicks()
    this.working = false
  }
}
</script>

<style scoped>
  .custombox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
    }
  .iframe {
    width: 100%;
    height: 100%;
    min-height: 900px;
  }
</style>