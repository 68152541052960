<template>
  <div class="">
    <BreadCrumb/>
      <!-- <OverView/> -->
    <h5 class="text-center mt-5 mb-5">Mailing übersicht</h5>
    <transition name="fade">
      <Loading v-if="working && !done || Loading" />
    </transition>
    <transition name="fade">
      <div v-if="done">
        <div class="container">
          <div class="alert alert-success" role="alert">
            <p>Mailing wurde erstellt.</p>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="container" v-if="!working && !done || !Loading">
        <div class="row">
          <div class="col">

            <form class="row g-3" @submit.prevent="checkForm">
              <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" placeholder="Mailingname" v-model="name">
              </div>

              <div class="mb-3">
                <label for="description" class="form-label">Notiz</label>
                <textarea class="form-control" id="description" rows="3" v-model="description"></textarea>
              </div>

              <div class="mb-3">
                <select class="form-select" aria-label="Default select example" @change="setSender($event)">
                  <option selected disabled>Wähle einen Absender oder Trage einen ein.</option>
                  <option v-for="(sender, index) in senders" :key="index" :value="index">{{sender.name}}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="senderName" class="form-label">Absender Name</label>
                <input type="text" class="form-control" placeholder="Absender" v-model="sender.name" aria-label="senderName">
              </div>

              <div class="mb-3">
                <label for="senderEmail" class="form-label">Absender E-Mail-Adresse</label>
                <input type="email" class="form-control" placeholder="E-Mail-Adresse" v-model="sender.email" aria-label="senderEmail">
              </div>

              <div class="mb-3">
                <label class="form-label">Template</label>
                <SelectMailing @selectMailingChanged="selectMailingChanged" :preSelected="selectedMailing"/>
              </div>

              <div class="mb-3 text-left">
                <p>Artikelnummern</p>
                <div v-if="articleNumbers && Array.isArray(articleNumbers)">
                  <ul>
                    <li v-for="el in articleNumbers" :key="el">{{el}}</li>
                  </ul>
                </div>

                <div v-else-if="articleNumbers">
                  <Loading :msg="articleNumbers"/>
                </div>
                
                <div v-else>
                  Keine Artikelnummern gefunden.
                </div>
              </div>

              <transition name="fade">
                <div  v-if="errors.length > 0" class="mb-3">
                  <div class="alert alert-danger" role="alert">
                    <ul>
                      <li v-for="error in errors" :key="error">{{error}}</li>
                    </ul>
                  </div>
                </div>
              </transition>

              <div class="col-auto ">
                <button type="submit" :disabled="articleNumbers && !Array.isArray(articleNumbers)" class="btn btn-primary mb-5">Update</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getFirestore, updateDoc, getDoc, doc, collection, getDocs, query } from "firebase/firestore"; 
// import SelectDistributor from "../smallComponents/SelectAllDistributor.vue"
import SelectMailing from "../smallComponents/SelectMailing.vue"
import Loading from '../Loading.vue'
import { getFunctions, httpsCallable } from "firebase/functions";
import BreadCrumb from '../smallComponents/MailBreadCrumb.vue'
// import OverView from '../smallComponents/MailOverview.vue'

export default {
  name: 'EditMailing',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  emits: ['selectDistibutorChanged'],
  components:{
    BreadCrumb,
    // SelectDistributor,
    SelectMailing,
    Loading,
  },
  watch: {
    name: function (val) {
      if(val){
        let index = this.name.indexOf(this.erroMessages.name)
        if (index > -1) {
          this.errors.splice(index, 1);
        }
      }
    },
  },
  data(){
    return {
      Loading: true,
      name: null,
      description: null,
      senders: [],
      sender: {email: "marketing@comline-shop.de", name: "ComLine GmbH"},
      selectedMailing: null,
      articleNumbers: null,
      selecte: [],
      errors: [],
      erroMessages: {
        name: 'Bitte fülle das Feld Mailingname aus.',
        sender: 'Bitte fülle die Sender-Daten aus.',
        selectedMailing: 'Bitte wähle ein Mailing.'
      },
      working: false,
      done: false,
    }
  },
  methods:{
    selectMailingChanged(val){
      this.errors = []
      this.selectedMailing = val
      this.articleNumbers = 'Artikelnummern werden geladen.'
      const getTemplates = httpsCallable(getFunctions(this.firebase, this.region), 'getArticleNumbers');
        getTemplates(val)
        .then(res=>{
          res.data.length > 0 ? (this.articleNumbers = res.data) : this.articleNumbers = null
        })
        .catch(e=>{
          console.error(e)
          this.errors.push(e)
        })
    },
    checkForm(e){
      e.preventDefault();
      if(this.name && this.selectedMailing ) {
        this.saveData()
      }
      else {
        this.errors = []
        !this.name && this.errors.push(this.erroMessages.name)
        !this.selectedMailing && this.errors.push(this.erroMessages.selectedMailing)
        !(this.sender.name && this.sender.email) && this.errors.push(this.erroMessages.sender)
      }
    },
    async saveData(){

      this.working = true
      const data = {
        name: this.name,
        description: this.description ? this.description : null,
        mailing: this.selectedMailing,
        sender: this.sender,
        articleNumbers: this.articleNumbers,
        priceImport: false
      }

      console.log(data)

      await updateDoc(doc(getFirestore(), "mailing",  this.$route.params.id), data)
      .catch(err=>{console.log(err)})

      this.$router.push({ name: 'Mailing empfaenger', params: { id: this.$route.params.id} })
    },
    async loadData(){
      await getDoc(doc(getFirestore(), "mailing", this.$route.params.id ))
      .then(snap =>{
        if (snap.exists()) {
        const mailing = snap.data()

        this.timestamp = mailing.timestamp
        this.name = mailing.name
        this.description = mailing.description
        this.selectedMailing = mailing.mailing
        this.sender = mailing.sender
        // Array.isArray(mailing.distributor) ? mailing.distributor.map(e=>{this.selectedDistibutor.push(e)}) : this.selectedDistibutor.push(mailing.distributor)
        this.articleNumbers = mailing.articleNumbers
        this.priceImport = mailing.priceImport

      
        return 'ok'
      } else {
        // console.log("No such document!");
        return 'error'
      }
      })
    },
    setSender(event){
      this.sender = this.senders[event.target.value]
    },
    async getSender(){
      const q = query(collection(getFirestore(), "sender"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.senders.push(doc.data())
      })
    },
  },
  async mounted(){
    await this.getSender()
    await this.loadData()
    this.Loading = false
  }
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
  .fade-enter-active,
  .fade-leave-active {
    transform: scaleY(1);
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.25s ease;
  }
</style>