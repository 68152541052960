<template>
  <div class="dropdown dropstart">
    <button class="btn btn-link btn-sm" style="padding: 0 !important;" type="button" id="dropdownLang" ref="dropdownLang" data-bs-toggle="dropdown" @click="dropdown.toggle()" aria-expanded="false">
      <span style="color: white; font-size: 30px;"><font-awesome-icon :icon="['fas', 'language']" /> </span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownLang" >
      <li><router-link :class="`dropdown-item ${$i18n.locale === 'de' ? 'active' : ''}`" :to="'de'"><country-flag country='de' size='small'/> Deutsch </router-link ></li>
      <li><router-link :class="`dropdown-item ${$i18n.locale === 'en' ? 'active' : ''}`" :to="'en'"><country-flag country='gb' size='small'/> English </router-link ></li>
      <li><router-link :class="`dropdown-item ${$i18n.locale === 'fr' ? 'active' : ''}`" :to="'fr'"><country-flag country='fr' size='small'/> Français </router-link ></li>
    </ul>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag-next'
import { Dropdown } from "@comlinedev/bootstrap5"

export default {
  name: 'dropdown',
  components: {
    CountryFlag
  },
  data(){
    return {
      dropdown: null
    }
  },
  mounted(){
    this.dropdown = new Dropdown(this.$refs.dropdownLang)
  }
}
</script>

<style>

</style>
