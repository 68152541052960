<template>
  <div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" id="priceloader" v-model="data.priceloader" @change="modal.show()">
    <label class="form-check-label" for="priceloader">Preisimport / deLUXE Schnittstelle</label> <Info class="ms-2" content="Wenn dieser Schalter aus ist, (nicht blau, sondern weiß) werden keine Preisimporte ausgeführt. Erst wenn dieser wieder eingeschaltet wird, startet der Preisimport wieder."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
  </div>
  <div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" id="email"  v-model="data.sendMail" @change="modal.show()">
    <label class="form-check-label" for="email">E-Mail-Versand</label><Info class="ms-2" content="Wenn dieser Schalter aus ist, (nicht blau, sondern weiß) werden keine E-Mails versendet. Erst wenn dieser wieder eingeschaltet wird, startet der E-Mail-Versand wieder."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
  </div>

  <div class="modal" tabindex="-1" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >Bestätigung</h5>
        </div>
        <div class="modal-body" >
          <p>Bist du sicher, dass du die Einstellung verändern willst. Dies kann auswirkungen auf Den E-Mail-Versand haben.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="loadData()">Abbrechen</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="color: white;" @click="update()">JA</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { getFirestore, updateDoc, getDoc, doc } from "firebase/firestore"; 
import { Modal } from "@comlinedev/bootstrap5"
import Info from "../smallComponents/Info.vue"

export default {
  name: 'Killswitch',
  data(){
    return{
      data: {},
      modal: null
    }
  },
  components: {
    Info
  },
  methods: {
    async update(){
      await updateDoc(doc(getFirestore(), "settings", 'killswitch'), this.data);
      this.loadData()
    },
    async loadData(){
      const settingssnap = await getDoc(doc(getFirestore(), "settings", 'killswitch'));
      if (settingssnap.exists()) {
        this.data = settingssnap.data()
        this.loading = false
      }
    }
  },
  async mounted(){
    this.loadData()
    this.modal = new Modal(this.$refs.modal, {backdrop: 'static', keyboard: false})
  }
}
</script>

<style scoped>

</style>