<template>
  <div class="col text-left">
    <h3>Verteiler</h3>
    <p></p>
    <br>
    <h4>Verteiler</h4>
    <p>Verteiler im herkömmlichen Sinne gibt es nicht. Es wird für den Versand eine Liste an Personen aus dem deLUXE generiert.</p>
    <p>Die Liste wird anhand der Sprache, "Personen Stichwörtern", und "Kunden Stichwörtern" generiert.</p>
    <br>
    
    <h4>Öffentliche Verteiler (Stichwörter)</h4>
    <p>Öffentlich werden grundsätzlich nur die "Mail-..." Personen Stichwörter angezeigt.</p>
    <p>Abonenten können in dem Preference-Center diese Personenstichwörter, die die Themen abbilden, wählen.</p>
    <p>Das manuelle Hinzufügen von Abonentenn ist nicht möglich! Jeder Abonenten muss seine Kategorien selbsständig wählen!</p>
    <br>

    <h4>Verteiler für den Testversand</h4>
    <p>Diese Verteiler sind ausschließlich für den Testversand.</p>
    <p>Es können verschiedene Verteiler angelegt werden, das anlegen eines Datensatzes für jeden Abonenten ist nicht erforderlich.</p>
    <br>

  </div>
</template>

<script>
export default {
  name: 'dkouDistributor'
}
</script>
