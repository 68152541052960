<template>
  <Loading v-if="!mailing" />
  <div v-html="mailing"></div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import Loading from '../Loading.vue'

export default {
  name: 'showMailing',
  components: {
    Loading
  },
  data(){
    return{
      mailing: null
    }
  },
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  methods:{
    async lodData(){
      const getMailingWithData = await httpsCallable(getFunctions(this.firebase, this.region), 'getMailingWithData');

      await getMailingWithData({mailingId: this.$route.params.id, subscriber: this.$route.params.subscriber})
      .then(res=>{
        this.mailing = res.data
      })
      .catch(e=>{
        console.error(e)
      })
    }
  },
  mounted(){
    this.lodData()
  },
}
</script>

<style>
  #card {
    cursor: pointer;
  }
</style>
