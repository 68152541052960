import {createStore} from "vuex";
import { onSnapshot, getFirestore, doc } from "firebase/firestore";

export default createStore({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    data: false,
    loading: true,
    settings: {},
  },
  getters: {
    user(state){
      return state.user
    },
    data(state){
      return state.data
    },
    loading(state){
      return state.loading
    },
    settings(state){
      return state.settings
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_SETTINGS_FTP(state, settings) {
      state.settings.ftp = settings;
    },
    SET_SETTINGS_WEBSERVER(state, settings) {
      state.settings.webserver = settings;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      // console.log(user)
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", user);
      } else {
        commit("SET_USER", null);
      }
    },
    async fetchData({commit}){
      onSnapshot(doc(getFirestore(), "settings", "webserver"), (doc) => {
        const settingsData = doc.data().url
        commit('SET_SETTINGS_WEBSERVER', settingsData)
      });

    }
  }
});