<template>
  <div>    
    <Notifications v-if="user && this.$route.name !== 'Mailing-HTML'"/>
    <Nav v-if="this.$route.name !== 'Mailing-HTML'"/>
    <router-view />
  </div>
</template>

<script>
import Nav from "./components/Navbar"
import { mapGetters } from "vuex";
import Notifications from "./components/smallComponents/Notifications.vue"

export default {
  name: "App",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    Nav,
    Notifications,
  },
  methods: {

  },
  mounted() {

  },
};
</script>
