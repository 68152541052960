<template>
  <div>
    <div class="container text-center">
      <div class="row">
        <div class="col">
          <h1 class="mb-5 mt-5" >Home</h1>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <States/>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <MailingsShort/>
        </div>
        <div class="col">
          <UsersShort/>
        </div>
      </div>
      <div class="row mt-4 mb-4">
        <div class="col">
          <NewUsers/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailingsShort from "../components/Home/Mailings.vue"
import UsersShort from "../components/Home/Users.vue"
import States from "../components/Home/States.vue"
import NewUsers from "../components/Home/UserConfirmation.vue"

export default {
  name: 'Home',
  components: {
    MailingsShort,
    UsersShort,
    States,
    NewUsers
  },
}
</script>

<style scoped>

</style>