import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBedzPIqIFQ5stDDo_Bwj8zrEr8Lfay17c",
  authDomain: "clgmbh-newsletter.firebaseapp.com",
  projectId: "clgmbh-newsletter",
  storageBucket: "clgmbh-newsletter.appspot.com",
  messagingSenderId: "26419610495",
  appId: "1:26419610495:web:44d839147001106b8f504a"
};

const region = 'europe-west3';

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const db = getFirestore(firebase);
const functions = getFunctions(firebase, region);

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}
console.log(location.hostname)
if (location.hostname === '192.168.140.93') {
  connectAuthEmulator(auth, 'http://192.168.140.93:9099');
  connectFirestoreEmulator(db, '192.168.140.93', 8080);
  connectFunctionsEmulator(functions, '192.168.140.93', 5001);
}

export {firebase, region, auth} 