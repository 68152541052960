<template>
  <div>
    <button type="submit" class="btn btn-primary" style="min-width: 120px;" v-if="!saving && !done">{{text}}</button>
    <button class="btn btn-secondary" style="min-width: 120px;" disabled v-if="saving  && !done"> <PulseLoader :color="'#ffffff'" size="10px" style="padding: 0; margin: 0;"/> </button>
    <button class="btn btn-success" style="min-width: 120px;" disabled v-if="!saving && done"> <font-awesome-icon :icon="['fas', 'check']" />  Abgeschlossen </button>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  props:{
    saving: Boolean,
    text: String,
    done: Boolean
  },
  components:{
    PulseLoader
  }
}
</script>

<style>
</style>
