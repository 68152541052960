<template>
  <div id="loading ">
    <div style="text-align: center;">
      <pulse-loader :color="'rgba(64, 137, 201, 1)'"></pulse-loader>
      <p v-if="msg">{{msg}}</p>
      <p v-else>Loading...</p>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'Loading',
  components: {
    PulseLoader
  },
  props:{
    msg: String
  },
  data(){
    return{

    }
  },
  methods:{

  },
  mounted(){

  },
}
</script>

<style>
#loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
