<template>
  <div id="showMailing">
    <Loading v-if="working" />
    <div class="container" v-else>
      <div class="col">
        <h5 class="mb-5 mt-5">Mailngs</h5>
        <div class="shadow-none p-3 mb-5 bg-light rounded">
          Hier werden alle Mailings aufgelistet, die noch nicht Versendet wurden. (Testversand ist keine Versand)
        </div>
        <input type="text" autocomplete="false" class="form-control mb-1" id="search" placeholder="Suchen" v-model="search">
        <div class="custombox">
          <table class="table mb-0">
            <thead>
              <tr>
                <th style="background-color: white;" class="sticky-top" scope="col">Name</th>
                <th style="background-color: white;" class="sticky-top" scope="col">Erstellt</th>
                <th style="background-color: white;" class="sticky-top" scope="col">Preisimport</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data) in tableDataFiltered" :key="data.id" v-show="!data.data?.sendMail" class="clickable">
                <td @click="goTo(data.id)">{{data.data.name}}</td>
                <td @click="goTo(data.id)"><b>{{data.data.timestamp.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })}}</b> {{data.data.timestamp.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})}} Uhr </td>
                <td @click="goTo(data.id)"><div v-if="data.data.priceImport?.date">
                  <b>{{data.data.priceImport?.date.toDate().toLocaleDateString('de-DE')}}</b>
                   {{data.data.priceImport?.date.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})}} Uhr </div></td>
                <td>
                  <span class="clickable"><DropDown :id="data.id"/></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../Loading.vue"
import DropDown from "../smallComponents/MailDropDownMenu.vue"
import { getFirestore, getDocs, query, collection, orderBy } from "firebase/firestore"; 

export default {
  name: 'selectMailing',
  components:{
    Loading,
    DropDown
  },
  data(){
    return{
      working: true,
      list: [],
      use: null,
      search: ''
    }
  },
  computed:{
    tableDataFiltered(){
      return this.list.filter(data => {
        return data.data.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods:{
    async loadData(){
     const q = query(collection(getFirestore(), "mailing"), orderBy('timestamp', 'desc'));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // console.log(doc.data())
        this.list.push({ id: doc.id, data: doc.data()})
      })
      this.working = false
    },
    goTo(id){
      this.$router.push({ name: 'Infoseite', params: { id: id } })
    }
  },
  mounted(){
    this.loadData()
  },
}
</script>

<style scoped>
  #card {
    cursor: pointer;
  }
  .custombox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
  }
  .clickable {
    cursor: pointer;
  }
  .clickable:hover {
    color: darkgray;
  }
</style>
