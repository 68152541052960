<template>
    <div class="toast-container position-absolute danger" style="z-index: 1090; right: 30px; top: 90px;" >
      <Toast :autohide="false" v-if="killswitch.priceimport">
        <div class="toast-header d-flex align-items-center">
          <font-awesome-icon style="width: 35px; height: 20px;" :icon="['fas', 'bell-exclamation']" size="1x" color="red" /> 
          <h6 class="me-auto">Preisimport - Error</h6><br>
          <small class="text-muted">{{convertDate(killswitch.priceimport)}}</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Der Preisimport wurde angehalten. - Killswitch
        </div>
      </Toast>
      <Toast :autohide="false" v-if="killswitch.sendmail">
        <div class="toast-header d-flex align-items-center">
          <font-awesome-icon style="width: 35px; height: 20px;" :icon="['fas', 'bell-exclamation']" size="1x" color="red" /> 
          <h6 class="me-auto">Mailversand angehalten</h6><br>
          <small class="text-muted">{{convertDate(killswitch.sendmail)}}</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Der Mailversand wurde angehalten. - Killswitch
        </div>
      </Toast>
      <template v-if="notifications">
        <template v-for="(note, index) in notifications" :key="index">
          <Toast :autohide="false" :id="note.id" v-if="note.userId === this.$store.state.user.data.uid || !note.userId">
            <div class="toast-header d-flex">
              <font-awesome-icon style="width: 35px; height: 20px;" :icon="['fas', 'bell']" size="1x"/> 
              <button type="button" class="btn-close ms-auto" :data-bs-dismiss="note.id ? note.id : 'Toast'" @click="deleteMe(note.id)" aria-label="Close"></button>
            </div>
            <div class="toast-body">
              {{note.message}}
            </div>
          </Toast>
        </template>
      </template>
    </div>
</template>

<script>
import Toast from "./Toast.vue"
import { getFirestore, doc, onSnapshot, collection, deleteDoc } from "firebase/firestore"; 

export default {
  name: 'Notifications',
  components:{
    Toast
  },    
  data(){
    return {
      notifications: [],
      killswitch: {
        priceimport: false,
        sendmail: false
      },
      stopKillswitchStates: null,
      stopNotifications:null
    }
  },
  methods:{
    loadNotifications(){
      this.stopNotifications = onSnapshot(collection(getFirestore(), "notifications"), (snap) => {
        this.notifications = []
        snap.forEach((doc) => {
          let note = doc.data()
          note.id= doc.id
          this.notifications.push(note)
        })
      });
    },
    deleteMe(id){
      deleteDoc(doc(getFirestore(), "notifications", id))
    },
    loadKillswitch(){
      this.stopKillswitchStates = onSnapshot(doc(getFirestore(), "settings", "killswitch"), (doc) => {
        const states = doc.data()
        
        if(!states.priceloader) this.killswitch.priceimport = new Date()
        if(!states.sendMail) this.killswitch.sendmail = new Date()
      });
    },
    convertDate(date){
      // console.log(date.toLocaleDateString())
      return `${date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})} Uhr`
    },
  },
  mounted(){
  this.loadKillswitch()
  this.loadNotifications()
  },
  unmounted(){
    this.stopKillswitchStates()
    this.stopNotifications()
  }
}
</script>

<style scoped>

</style>
