<template>
  <div class="container mt-5">
    <div class="alert alert-success" role="alert">
      <p>{{$t('message.signup.success')}}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Success',
}
</script>

<style scoped>

</style>