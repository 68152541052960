<template>
  <div class=" mb-5">
    <h5 class="text-center mb-5 mt-5">Übersetzung für die Stichworte</h5>
    <transition name="fade">
      <Loading v-if="working" />
    </transition>
    <div class="container" v-if="!working">
      <div class="row">
        <div class="col">

        <form class="row g-3" @submit.prevent="saveData">
          <div class="custombox">
            <div class="row">
              <div class="fs-3 pb-2 col">Stichwort</div>
              <div class="fs-3 pb-2 col">Deutsch</div>
              <div class="fs-3 pb-2 col">Englisch</div>
              <div class="fs-3 pb-2 col">Französisch</div>
            </div>
            <draggable v-model="allCategories" item-key="id">
              <template #item="{element}">
                <div class="row dragable">
                  <div class="col">{{element.name}}</div>
                  <div class="col"> <input type="text" class="form-control" :id="`${element.name}-de`" :placeholder="`${element.name} (DE)`" v-model="enrichedData[element.name].de"></div>
                  <div class="col"> <input type="text" class="form-control" :id="`${element.name}-en`" :placeholder="`${element.name} (EN)`" v-model="enrichedData[element.name].en"></div>
                  <div class="col"> <input type="text" class="form-control" :id="`${element.name}-fr`" :placeholder="`${element.name} (FR)`" v-model="enrichedData[element.name].fr"></div>
                </div>
              </template>
            </draggable>
          </div>

          <div class="col d-flex justify-content-center">
            <SaveButton :saving="saving" text="Speichern" :done="done"/>
          </div>
        </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirestore, setDoc, doc, getDoc} from "firebase/firestore"; 
import { httpsCallable, getFunctions } from "firebase/functions";
import { firebase, region } from "../../plugins/firebase";
import Loading from "../Loading.vue"
import SaveButton from "../smallComponents/SaveButton.vue"
import draggable from 'vuedraggable'

export default {
  name: 'EditCategories',
  components:{
    Loading,
    SaveButton,
    draggable
  },
  data(){
    return {
      drag: false,
      name: {},
      enrichedData: {},
      allCategories: null,
      errors: [],
      erroMessages: {
        name: 'Bitte fülle Alle Felder aus.',
      },
      working: true,
      done: false,
      preSelected: {},
      saving: false
    }
  },
  methods:{
    async saveData(){
      this.saving = true
      this.allCategories.forEach((el, index)=>{
        console.log(el.name)
        this.enrichedData[el.name].id = index
      })

      await setDoc(doc(getFirestore(), "enrichedCategories", "data"), this.enrichedData)
      this.saving = false
      this.done = true  
      setTimeout(()=>{this.done = false}, 2000);

    },
    async loadData(){
      let getPersonKeywords = await httpsCallable(getFunctions(firebase, region), 'getPersonKeywords');
      await getPersonKeywords()
      .then((data)=>{
        this.allCategories = []
        data.data.forEach((el, index)=>this.allCategories.push({name:el, id:index}))
        data.data.forEach(el=>this.enrichedData[el] = {de:"", en:"", fr:""})
        console.log(this.allCategories)
      })
      .catch(err=>{ 
        console.error("error here")     
        console.error(err)
      })

      const enrichedCategoriesSnap = await getDoc(doc(getFirestore(), "enrichedCategories", "data" ));
      if (enrichedCategoriesSnap.exists()) {
        this.enrichedData = Object.assign(this.enrichedData, enrichedCategoriesSnap.data())
      }
      this.allCategories.forEach(el=> el.id = this.enrichedData[el.name].id)
      this.allCategories.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))

    }
  },
  async beforeMount(){
    console.log('lade Daten')
    await this.loadData()
    this.working= false
  }
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
  .fade-enter-active,
  .fade-leave-active {
    transform: scaleY(1);
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.25s ease;
  }
  .dragable {
    padding: 6px 0;
    cursor: pointer !important;
  }
</style>