<template>
  <button class="btn btn-link" @click="showData()" v-if="!show">Rohdaten ansehen</button>
  <table v-show="show">
    <thead>
      <th>Datum</th>
      <th>E-Mail</th>
      <th>Grund</th>
    </thead>
    <tr v-for="(each, index) in data" :key="index">
      <td>{{getDate(each.created)}}</td>
      <td>{{each.email}}</td>
      <td>{{each.reason}}</td>
    </tr>
  </table>
</template>

<script>
import moment from "moment-timezone"

export default {
  name: 'ReportData',
  props: {
    data: Object,
    name: String
  },
  data: function() {
    return {
      show: false
    }
  },
  methods: {   
    getDate(date){
      return moment.unix(date).format('DD.MM.yy')
    },
    showData(){
      this.show = true
    }
  },
};

</script>

<style scoped>
  td {
    padding: 3px;
  }
</style>