<template>
  <div class="col text-left">
    <h3>Versand</h3>
    <p></p>
    <br>
    <h4>Erstellen eines Mailings</h4>
    <p>Für den Versand ist es erforderlich, ein Mailing zu erstellen.</p>
    <p>Es muss ein Name definiert werden, mindestens ein Verteiler Gewählt werden, und ein Template vom Server ausgewählt werden.</p>
    <p>Nach der Wahl des Templates werden die Artikelnummern aus den Preisvariablen ausgelesen.</p>
    <br>
    
    <h4>Versand</h4>
    <p>Bei Mailings bei den Artikelnummern im Template gefunden wurden muss vor dem Versand ein Preisimport erfolgen.</p>
    <p>Jedes Mailing kann nur einmal versendet werden. Nach dem Versand ist ein Sonderversand, an einzelne Abonenten aus der Datenbank, möglich.</p>
    <br>

    <h4>Testversand</h4>
    <p>Der Testversand ist nur vor dem Versand des Mailings möglich.</p>
    <br>

    <!-- <h4>Sonderversand</h4>
    <p>Mit dem Sonderversand können nach dem Versand eines Mailings weitere Abonenten angeschrieben werden.</p>
    <p>Abonenten die das Mailing bereits erhalten haben werden ausgegraut.</p>
    <br> -->

    <h4>Preisimport</h4>
    <p>Die Preise werden anhand der Kundennummer und der Artikelnummer aus dem deLuxe importiert.</p>
    <br>

    <h4>Klickreport</h4>
    <p>Um die Links für die Auswertung zu benennen muss das Atribut <code>name=""</code> verwendet werden.</p>
    <p><code>&lt;a name="Googlen" href="https://www.google.de/search?q=ComLine"&gt; Jetzt Googlen &lt;/a&gt; </code></p>
    <p>Wird das <code>name</code> Atribut verwendet, wird der Inhalt bei der Auswertung angezeigt.</p>
    <p>Wird das <code>name</code> Atribut nicht verwendet wird nach einem <code>alt</code> Atribut in dem Anker Tag gesicht. und dieser inhalt verwendet, falls vorhaben.</p>
    <p><code>&lt;a href="https://www.google.de/search?q=ComLine"&gt;&lt;img alt="Google Logo" src="https://google.de/logo"/&gt;&lt;/a&gt; </code></p>
    <p>Ist auch kein <code>alt</code> Atribut vorhanden, wird die Domain als Name verwendt.</p>

  </div>
</template>

<script>
export default {
  name: 'dokuDelivery'
}
</script>
