<template>
    <div v-if="working">
      <Loading :msg="message"/>
    </div>
    <div v-else>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Link</th>
              <th scope="col">Name für Klickreport</th>
              <th scope="col">Aufbau</th>
              <th scope="col">Erreichbarkeit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in list" :key="item">
              <td class="text-left">
                <a :href="item.link" target="_blank">{{item.link}}</a>
              </td>
              <td class="text-left">{{checkName(item)}}</td>
              <td class="text-center"><Check toReturn="validateURL" :url="item.link" /></td>
              <td class="text-center"><Check toReturn="Reachable" :url="item.link" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>
import Loading from '../Loading.vue'
import Check from '../smallComponents/CheckUrl.vue'
import { mapGetters } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import extractDomain from "extract-domain";

export default {
  name: 'Linklist',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  components: {
    Loading,
    Check
  },
  data(){
    return {
      working: true,
      message: 'Links werden geladen.',
      list: []
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings",
    })
  },
  methods:{
    async loadData(){
      const docSnap = await getDoc(doc(getFirestore(), "mailing", this.$route.params.id ));

      if (docSnap.exists()) {
        const getTemplates = httpsCallable(getFunctions(this.firebase, this.region), 'getLinks');
        await getTemplates(docSnap.data().mailing)
        .then(res=>{
          // console.log(res)
          this.list = res.data
          this.analyse()
          this.working = false
        })
        .catch(e=>{
          console.error(e)
          this.working = false
          this.error = true
        })
      } else {
        // console.log("No such document!");
      }

    },
    analyse(){
      let newList = []
      this.message = 'Links werden analysiert.'
      this.list.forEach((value)=>{ 
        let splited = value.link.split(':')
        
        if (splited[0] === 'http') {newList.push(value)}
        if (splited[0] === 'https') {newList.push(value)}
      })
      this.list = newList
    },
    async getStatus(url) {
      const isReachable = require('is-reachable');
      // console.log(await isReachable(url));
      return await isReachable(url)
    },
    checkName(val){
      let name
      if(val.name){
        name = val.name
      } else{
        name = extractDomain(val.link)
      }
      return name
    }
  },
  mounted(){
    this.loadData()
  },
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
  .text-left {
    text-align: left !important;
  }

</style>