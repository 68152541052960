<template>
  <div class="dropdown">
    <button class="btn btn-link btn-sm" style="padding: 0 !important;" type="button" :ref="`dropdownMenu${id}`" data-bs-toggle="dropdown" @click="dropDown.toggle()" aria-expanded="false">
    <font-awesome-icon :icon="['fas', 'ellipsis-h']" color="grey"/> 
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
      <li><router-link  class="dropdown-item" :to="`${setPath()}edit${test?'test': ''}/${id}`">Bearbeiten</router-link ></li>
      <li><span  class="dropdown-item" @click="this.$emit('delete', id)">Löschen</span ></li>
    </ul>
  </div>
</template>

<script>
import { Dropdown } from "@comlinedev/bootstrap5"

export default {
  name: 'dropdown',
  props:{
    id: String,
    path: String,
    test: Boolean
  },
  methods:{
    setPath(){
      return this.path? this.path+'/': '';
    }
  },
  emits: ['delete'],
  data(){
    return {
      dropDown: null
    }
  },
  mounted(){
    this.dropDown = new Dropdown(this.$refs[`dropdownMenu${this.id}`])
  }
}
</script>

<style>

</style>
