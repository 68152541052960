<template>
  <div class="toast" :ref="idToast" role="alert" aria-live="assertive" aria-atomic="true">
    <slot></slot>
  </div>
</template>

<script>
import { Toast } from "@comlinedev/bootstrap5"

export default {
  name: 'Toast',
  data(){
    return{
      idToast: this.id ? this.id : 'Toast',
      toast: null
    }
  },
  props:{
    id: String,
    autohide: Boolean
  },
  mounted(){
    this.toast = new Toast(this.$refs[this.idToast], {autohide: this.autohide})
    this.toast.show()
  },
}
</script>