<template>
  <apexchart
    type="bar"
    :options="chartOptions"
    :series="chartOptions.series"
  ></apexchart>
</template>

<script>

export default {
  name: 'LineChart',
  props: {
    dataSets: Object,
    name: String
  },
  data: function() {
    return {
      chartOptions: {
        series: [{
          name: this.name,
          data: this.dataSets
        }],
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        title: {
          text: this.name,
          align: 'center'
        },
        yaxis: {        
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
            
          },
          title: {
            text: this.name
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false
          }
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val.toFixed(0)
            }
          }
        }
      }
    }
  },
  methods: {   

  },
  mounted(){
    // console.log(this.dataSets)
  }
};

</script>