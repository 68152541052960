<template>
  <apexchart
    type="bar"
    :options="chartOptions"
    :series="chartOptions.series"
    @click="click"
    ></apexchart>
</template>

<script>

export default {
  name: 'HorizontalChart',
  props: {
    dataSets: Object,
    links: Array
  },
  data: function() {
    return {
      chartOptions: {
        title: {
          text: 'Mail Klicks',
          align: 'center'
        },
        chart: {
          type: 'bar',
          events: {
            dataPointSelection: function(event, chartContext, obj) {
              console.log(obj.w.config.series)
              return document.location.href = obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex].z;
            }
          }
        },
        xaxis: {
          categories: this.dataSets.names,
        },
        series: [{
            data: this.dataSets.data1,
            name: 'Mehrfachklicks'
          }, {
            data: this.dataSets.data2,
            name: 'Klicks'
          }],
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
      }
    }
  },
  methods: {   
    click(event, chartContext, obj) { 
      if(this.links.length > 0 && this.links[obj.dataPointIndex]){
        document.location.href = this.links[obj.dataPointIndex]
      }
    },
  },
  mounted(){
    // console.log(this.dataSets)
  }
};
</script>