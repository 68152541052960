<template>
  <div class="container mt-3">
    <h3 class="mb-3 text-center">Sonderversand</h3>
    <Loading v-if="working"/>
    <div v-else-if="!done">
      <div class="mt-3 mb-3">
        <h5>{{mailing.name}}</h5>

        <p v-if="mailing.description">Beschreibung: {{mailing.description}}</p>
        <p>Template: <b>{{mailing.mailing}}</b></p>
        <p v-if="mailing?.articleNumbers && mailing.articleNumbers.length > 0 && mailing.priceImport">Preisimport:
          <template v-if="mailing.priceImport.state === 'done'"><b>{{mailing.priceImport.date.toDate().toLocaleDateString('de-DE')}}</b> {{mailing.priceImport.date.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})}} Uhr </template>
          <template v-else-if="!mailing.priceImport"><span style="color: red;"><font-awesome-icon :icon="['fas', 'times']" /></span> Fehlt</template>
        </p>
        <!-- <template  v-if="distributors.length > 0">
          <template v-for="(distributor, index) in distributors" :key="index">
          <p>Verteiler-<b>{{index+1}}</b>: <b>{{distributor.name}}</b></p>
          <p>Aktive Abonenten: <b>{{distributor.selectedUser.length}}</b></p>
          </template>
          <template  v-if="distributors.length > 1">
            <p>Abonenten bereinigt: {{Abonentenbereinigt(distributors)}}</p>
          </template>
        </template> -->
      </div>
      <hr>

      <!-- <div class="mb-3">
        <label class="form-label">Empfänger wählen</label>
        <transition name="fade">
          <div> 
            <SelectUser @selectUserChange="selectUserChange" :disabled="disabledUsers" />
          </div>
        </transition>
      </div> -->

      <transition name="fade">
        <div  v-if="errors.length > 0" class="mb-3">
          <div class="alert alert-danger" role="alert">
            <ul>
              <li v-for="error in errors" :key="error">{{error}}</li>
            </ul>
          </div>
        </div>
      </transition>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="dsgvoCheck" id="DSGVO">
        <label class="form-check-label" for="DSGVO">
           Ich handel im Sinne der DSGVO. Alle Personen haben ihr Einwilligung zum Erhalt der E-Mail abgegeben.
        </label>
      </div>

      <div class="mt-5 text-center">
        <button class="btn btn-primary" @click="dsgvoCheck && (selectedUser.length > 0) && sendMail()" :disabled="!dsgvoCheck || !(selectedUser.length > 0) "><font-awesome-icon :icon="['fas', 'paper-plane']" /> Absenden</button>
      </div>
    </div>
    <div  v-else class="alert alert-success">Das Mailing wurde für den Versand vorbereitet.</div>
  </div>
</template>

<script>
import Loading from '../Loading.vue'
import { mapGetters } from "vuex";
import { getFirestore, doc, getDoc, collection, query, getDocs, addDoc} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
// import SelectUser from "../smallComponents/SelectUser.vue"
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'SpecialDelivery',
  components:{
    Loading,
    // SelectUser
  },
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  data(){
    return {
      working: true,
      mailing: null,
      date: null,
      sender: {email: "marketing@comline-shop.de", name: "ComLine GmbH"},
      distibutor: null,
      done: false,
      errors: [],
      senders: [],
      selected: null,
      distributors: [],
      disabledUsers: [],
      selectedUser: [],
      dsgvoCheck: false
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings",
    })
  },
  methods:{
    async mailLog(action, id){
      let mailId = this.$route.params.id ? this.$route.params.id : id;
      const ref = await collection(getFirestore(), "mailing", mailId, "log");
      await addDoc(ref, {action: action, time: new Date()})
    },
    async loadData(){
      await this.getMailingData()
      // await this.getDistributorData()
      // await this.getSender()
      await this.getDelivered()
      this.working = false
    },
    async getMailingData(){
        const docSnap = await getDoc(doc(getFirestore(), "mailing", this.$route.params.id ));

        if (docSnap.exists()) {
          // console.log(docSnap.data())
          this.mailing = docSnap.data()
        } else {
          this.errors.push('mailing')
          console.error("No such document!");
        }
    },
    // async getDistributorData(){
    //   if(Array.isArray(this.mailing.distributor)){
    //     for await (const tempDistributor of this.mailing.distributor) {
    //       const distibutorSnap = await getDoc(doc(getFirestore(), "distributor", tempDistributor ));
    //       if (distibutorSnap.exists()) {
    //         this.distributors.push(distibutorSnap.data())
    //       } else {
    //         console.error('Fehler beim abrufen der VerteilerDaten.')
    //       }
    //     }
    //   } else {
    //     const distibutorSnap = await getDoc(doc(getFirestore(), "distributor", this.mailing.distributor ));
    //     if (distibutorSnap.exists()) {
    //         this.distributors.push(distibutorSnap.data())
    //     } else {
    //       console.error('Fehler beim abrufen der VerteilerDaten.')
    //     }
    //   }
    // },
    async getDelivered(){
      const mailingRef = await doc(getFirestore(), "mailing", this.$route.params.id);
      const sendsDocs = await getDocs(query(collection(mailingRef, "webview")))
      let ids = []
      sendsDocs.forEach((doc) => {
          ids.push(doc.id)
      });
      this.disabledUsers = ids
    },
    selectUserChange(val){
      this.selectedUser = val
    },
    async sendMail(){
      this.mailLog('Mailing wurde versendet.')
      let checked = this.checkData()
      if(checked.length <= 0){
        this.working = true;

        let objectToSend = {
          selectedUser: this.selectedUser,
          mailingId: this.$route.params.id,
          sender: this.sender,
          uid: this.$store.state.user.data.uid
        }
        
        const setSpecialDelivery = httpsCallable(getFunctions(this.firebase, this.region), 'setSpecialDelivery');
        await setSpecialDelivery(objectToSend)
    
        this.done = true

        setTimeout(()=>{this.$router.push('/admin')}, 2000);
        this.working = false
        
      } else {
        console.error('Bitte prüfe deine Eingaben.')
      }
    },
    Abonentenbereinigt(distributors){
      let subscribers = []
      distributors.forEach(el=>{subscribers = [...subscribers, ...el.selectedUser]})
      return subscribers.length
    },
    checkData(){
      let check = []
      this.mailing.articleNumbers && this.mailing.articleNumbers.length > 0 && !this.mailing.priceImport && (check.push('Die Preise wurden nicht Importiert.')) &&
      this.mailing.priceImport.state !== 'done' && (check.push('Der Preisimport ist nicht abgeschlossen.'))
      this.isTimed && !this.date && (check.push('Es wurde kein Zeitpunkt für den Versand festgelegt.'))
      !this.sender.email && (check.push('Die Absender-E-Mail-Adresse muss festgelegt werden.'))
      !this.sender.name && (check.push('Der Absender muss festgelegt werden.'))

      this.errors = check
      return check
    },
  },
  mounted(){
    this.loadData()
  },
}
</script>

<style scoped>
  .scrollBox {
    border: 1px #f2f2f2 solid;
    max-height: 900px; 
    overflow-y: scroll;
  }
</style>