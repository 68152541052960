<template>
  <div class="text-left">
    <h5 class="mb-5 mt-5 text-center">Report</h5>
    <div class="container mb-5">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <nav aria-label="Page navigation example">
              <ul class="pagination" style="cursor: pointer;">
                <li :class="`page-item ${show === 'Bounces' && 'active'}`"><a class="page-link" @click="show = 'Bounces'">Bounces</a></li>
                <li :class="`page-item ${show === 'Blocks' && 'active'}`"><a class="page-link" :active="show === 'Blocks'" @click="show = 'Blocks'">Blocks</a></li>
                <li :class="`page-item ${show === 'Spam' && 'active'}`"><a class="page-link" :active="show === 'Spam'" @click="show = 'Spam'">Spam</a></li>
                <li :class="`page-item ${show === 'Invalide' && 'active'}`"><a class="page-link" :active="show === 'Invalide'" @click="show = 'Invalide'">Invalide E-Mail</a></li>          
                <li :class="`page-item ${show === 'Unsubscribe' && 'active'}`"><a class="page-link" :active="show === 'Unsubscribe'" @click="show = 'Unsubscribe'">Abmeldungen</a></li>
                <li :class="`page-item ${show === 'AutoUnsubscribe' && 'active'}`"><a class="page-link" :active="show === 'AutoUnsubscribe'" @click="show = 'AutoUnsubscribe'">Auto-Abmeldungen</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'Bounces'">
        <div class="col">
          <div class="custombox" v-if="!working">
            <LineTimeChart :dataSets="bouncesTimedData" name="Bounces"/>
            <div class="d-flex justify-content-between m-3">
              <p>Gesammt: {{bouncesCount}}</p><p v-if="bouncesDate">Letztes Update: {{convertDate(bouncesDate)}}</p>
            </div>
          </div>
          <div v-if="!working">
            <ShowRawReportdata :data="bouncesSorted"/>
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'Blocks'">
        <div class="col">
          <div class="custombox"  v-if="!working">
            <LineTimeChart :dataSets="blocksTimedData" name="Blocks"/>
            <div class="d-flex justify-content-between m-3">
              <p>Gesammt: {{blocksCount}}</p><p v-if="blocksDate">Letztes Update: {{convertDate(blocksDate)}}</p>
            </div>
          </div>
          <div v-if="!working">
            <ShowRawReportdata :data="blocksSorted"/>
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'Spam'">
        <div class="col">
          <div class="custombox" v-if="!working">
            <LineTimeChart :dataSets="spamTimedData" name="Spam" />
            <div class="d-flex justify-content-between m-3">
              <p>Gesammt: {{spamCount}}</p><p v-if="spamDate">Letztes Update: {{convertDate(spamDate)}}</p>
            </div>
          </div>
          <div v-if="!working">
            <ShowRawReportdata :data="spamSorted"/>
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'Invalide'">
        <div class="col">
          <div class="custombox" v-if="!working">
            <LineTimeChart :dataSets="InvalideTimedData" name="Invalide E-Mail"/>
            <div class="d-flex justify-content-between m-3">
              <p>Gesammt: {{invalideCount}}</p><p v-if="invalideDate">Letztes Update: {{convertDate(invalideDate)}}</p>
            </div>
          </div>
          <div v-if="!working">
            <ShowRawReportdata :data="invalideSorted"/>
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'Unsubscribe'">
        <div class="col">
          <div class="custombox" v-if="!working">
            <LineTimeChart :dataSets="Unsubscribe" name="Abmeldungen"/>
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'AutoUnsubscribe'">
        <div class="col">
          <div class="custombox" v-if="!working">
            <LineTimeChart :dataSets="AutoUnsubscribe" name="Auto-Abmeldungen"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirestore, getDocs, getDoc, collection, doc} from "firebase/firestore"; 
import LineTimeChart from '../components/Report/LineTimeChartReport.vue'
import moment from "moment-timezone"
import ShowRawReportdata from "../components/Report/showReportData.vue"

export default {
  name: 'Evaluation',
  components: {
    LineTimeChart,
    ShowRawReportdata
  },
  data(){
    return{
      working: true,
      show: 'Bounces',
      bouncesTimedData: null,
      bouncesCount: 0,
      bouncesSorted: null,
      bouncesDate: null,
      blocksTimedData: null,
      blocksCount: 0,
      blocksSorted: null,
      blocksDate: null,
      spamTimedData: null,
      spamCount: 0,
      spamSorted: null,
      spamDate: null,
      InvalideTimedData: null,
      invalideCount: 0,
      invalideSorted: null,
      invalideDate: null,
      Unsubscribe: null,
      UnsubscribeCount: 0,
      AutoUnsubscribe: null,
      AutoUnsubscribeCount: 0

    }
  },
  methods: {
    async getBouncesTimed(){
      const docBounces = doc(collection(getFirestore(), "reports"), "bounces")
      const collectionBounces = await collection(docBounces, "data")
      const querySnapshot = await getDocs(collectionBounces);

      let snapshotData = []
      querySnapshot.forEach((doc) => {
        snapshotData.push(doc.data())
      })

      getDoc(docBounces)
      .then(d=>{
        this.bouncesDate = d.data().timestamp
      })
      
      this.bouncesCount = snapshotData.length

      // order by date (combine days)
      snapshotData.sort(function (a, b) {
        if(a.created <= b.created) {
          return -1;
        } else {
          return 1;
        }
      })

      this.bouncesSorted = snapshotData.reverse()

      let countedData = snapshotData.reduce(function (result, line) {
        var day = moment.unix(line.created).format("YYYY-MM-DD");
        if (!result[moment(day).format('x')]) {
          result[moment(day).format('x')] = 0;
        }
        result[moment(day).format('x')]++;
        return result;
      }, {});
      
      const finalData = []
      Object.entries(countedData).forEach((data)=>{
        finalData.push([Number(data[0]), data[1]])
      })
      this.bouncesTimedData = finalData
    },
    async getBlocksTimed(){
      const docBlocks = doc(collection(getFirestore(), "reports"), "blocks")
      const collectionBounces = await collection(docBlocks, "data")
      const querySnapshot = await getDocs(collectionBounces);

      let snapshotData = []
      querySnapshot.forEach((doc) => {
        snapshotData.push(doc.data())
      })

      getDoc(docBlocks)
      .then(d=>{
        this.blocksDate = d.data().timestamp
      })

      this.blocksCount = snapshotData.length

      // order by date (combine days)
      snapshotData.sort(function (a, b) {
        if(a.created <= b.created) {
          return -1;
        } else {
          return 1;
        }
      })

      this.blocksSorted = snapshotData.reverse()

      let countedData = snapshotData.reduce(function (result, line) {
        var day = moment.unix(line.created).format("YYYY-MM-DD");
        if (!result[moment(day).format('x')]) {
            result[moment(day).format('x')] = 0;
        }
        result[moment(day).format('x')]++;
        return result;
      }, {});
      
      const finalData = []
      Object.entries(countedData).forEach((data)=>{
        finalData.push([Number(data[0]), data[1]])
      })
      this.blocksTimedData = finalData
    },
    async getSpamTimed(){
      const docSpam = doc(collection(getFirestore(), "reports"), "spam")
      const collectionBounces = await collection(docSpam, "data")
      const querySnapshot = await getDocs(collectionBounces);

      let snapshotData = []
      querySnapshot.forEach((doc) => {
        snapshotData.push(doc.data())
        // console.log(doc.data())
      })

      getDoc(docSpam)
      .then(d=>{
        this.spamDate = d.data().timestamp
      })

      this.spamCount = snapshotData.length

      // order by date (combine days)
      snapshotData.sort(function (a, b) {
        if(a.created <= b.created) {
          return -1;
        } else {
          return 1;
        }
      })

      this.spamSorted = snapshotData.reverse()

      let countedData = snapshotData.reduce(function (result, line) {
        var day = moment.unix(line.created).format("YYYY-MM-DD");
        if (!result[moment(day).format('x')]) {
            result[moment(day).format('x')] = 0;
        }
        result[moment(day).format('x')]++;
        return result;
      }, {});
      
      const finalData = []
      Object.entries(countedData).forEach((data)=>{
        finalData.push([Number(data[0]), data[1]])
      })
      this.spamTimedData = finalData
    },
    async getInvalideTimed(){
      const docInvalide = doc(collection(getFirestore(), "reports"), "invalide")
      const collectionBounces = await collection(docInvalide, "data")
      const querySnapshot = await getDocs(collectionBounces);

      let snapshotData = []
      querySnapshot.forEach((doc) => {
        snapshotData.push(doc.data())
        // console.log(doc.data())
      })

      getDoc(docInvalide)
      .then(d=>{
        this.invalideDate = d.data().timestamp
      })

      this.invalideCount = snapshotData.length

      // order by date (combine days)
      snapshotData.sort(function (a, b) {
        if(a.created <= b.created) {
          return -1;
        } else {
          return 1;
        }
      })

      this.invalideSorted = snapshotData.reverse()

      let countedData = snapshotData.reduce(function (result, line) {
        var day = moment.unix(line.created).format("YYYY-MM-DD");
        if (!result[moment(day).format('x')]) {
            result[moment(day).format('x')] = 0;
        }
        result[moment(day).format('x')]++;
        return result;
      }, {});
      
      const finalData = []
      Object.entries(countedData).forEach((data)=>{
        finalData.push([Number(data[0]), data[1]])
      })
      this.InvalideTimedData = finalData
    },
    // async getUnsubscribedTimed(){
    //   const collectionUnsubscribed = await collection(getFirestore(), "unsubscribed")
    //   const querySnapshot = await getDocs(collectionUnsubscribed);

    //   let snapshotData = []
    //   querySnapshot.forEach((doc) => {
    //     let min_date = moment(new Date()).subtract(2, 'years').unix();
    //     let date = moment(doc.data().timestamp.toDate()).unix();
    //     if(min_date < date){
    //       snapshotData.push(doc.data())
    //     }
    //   })
      
    //   this.UnsubscribeCount = snapshotData.length

    //   // order by date (combine days)
    //   snapshotData.sort(function (a, b) {
    //     if(a.timestamp <= b.timestamp) {
    //       return -1;
    //     } else {
    //       return 1;
    //     }
    //   })

    //   let countedData = snapshotData.reduce(function (result, line) {
    //     var day = moment(line.timestamp.toDate()).format("YYYY-MM-DD");
    //     if (!result[moment(day).format('x')]) {
    //       result[moment(day).format('x')] = 0;
    //     }
    //     result[moment(day).format('x')]++;
    //     return result;
    //   }, {});
      
    //   const finalData = []
    //   Object.entries(countedData).forEach((data)=>{
    //     finalData.push([Number(data[0]), data[1]])
    //   })
    //   this.Unsubscribe = finalData
    // },
    async getUnsubscribedTimed(){
      const collectionUnsubscribed = await collection(getFirestore(), "unsubscribed")
      const querySnapshot = await getDocs(collectionUnsubscribed);

      let snapshotData = []
      let snapshotDataAuto = []
      querySnapshot.forEach((doc) => {
        let min_date = moment(new Date()).subtract(2, 'years').unix();
        let date = moment(doc.data().timestamp.toDate()).unix();
        if(min_date < date){
          if(doc.data().reason === "auto") {
            snapshotDataAuto.push(doc.data())
          }
          if(doc.data().reason === "self") {
            snapshotData.push(doc.data()) 
          }
        }
      })
      
      this.UnsubscribeCount = snapshotData.length
      this.AutoUnsubscribeCount = snapshotDataAuto.length

      // order by date (combine days)
      snapshotData.sort(function (a, b) {
        if(a.timestamp <= b.timestamp) {
          return -1;
        } else {
          return 1;
        }
      })

            // order by date (combine days)
      snapshotDataAuto.sort(function (a, b) {
        if(a.timestamp <= b.timestamp) {
          return -1;
        } else {
          return 1;
        }
      })

      let countedData = snapshotData.reduce(function (result, line) {
        var day = moment(line.timestamp.toDate()).format("YYYY-MM-DD");
        if (!result[moment(day).format('x')]) {
          result[moment(day).format('x')] = 0;
        }
        result[moment(day).format('x')]++;
        return result;
      }, {});
      
      const finalData = []
      Object.entries(countedData).forEach((data)=>{
        finalData.push([Number(data[0]), data[1]])
      })
      this.Unsubscribe = finalData

      let countedDataAuto = snapshotDataAuto.reduce(function (result, line) {
        var day = moment(line.timestamp.toDate()).format("YYYY-MM-DD");
        if (!result[moment(day).format('x')]) {
          result[moment(day).format('x')] = 0;
        }
        result[moment(day).format('x')]++;
        return result;
      }, {});
      
      const finalDataAuto = []
      Object.entries(countedDataAuto).forEach((data)=>{
        finalDataAuto.push([Number(data[0]), data[1]])
      })
      this.AutoUnsubscribe = finalDataAuto
    },
    convertDate(date){
      return `${date.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })} ${date.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})} Uhr`
    },
  },
  async mounted(){
    await this.getBouncesTimed()
    await this.getBlocksTimed()
    await this.getSpamTimed()
    await this.getInvalideTimed()
    await this.getUnsubscribedTimed()
    this.working = false
  }
}
</script>

<style scoped>
  .custombox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
  }
</style>