<template>
  <Loading v-if="loading" />
  <form class="row g-3" @submit.prevent="update" v-else>

    <div class="mb-3">
      <label for="url" class="form-label">URL</label><br>
      <input type="text" class="form-control" id="url" placeholder="URL" v-model="data.url">
    </div>

    <div class="mb-3">
      <label for="user" class="form-label">Nutzername</label><br>
      <input type="text" class="form-control" id="url" placeholder="user" v-model="data.username">
    </div>

    <div class="mb-3">
      <label for="password" class="form-label">Passwort</label><br>
      <input type="password" class="form-control" id="url" placeholder="password" v-model="data.password">
    </div>

    <!-- <SaveButton :saving="saving" text="Speichern" :done="done"/> -->
  </form>
</template>

<script>
import { getFirestore, updateDoc, getDoc, doc } from "firebase/firestore"; 
// import SaveButton from "../smallComponents/SaveButton.vue"
import Loading from "../Loading.vue"

export default {
  name: 'FTP',
  data(){
    return{
      data: {},
      saving: false,
      loading: true,
      done: false
    }
  },
  components:{
    // SaveButton,
    Loading
  },
  methods: {
    async update(){
      this.saving = true
      await updateDoc(doc(getFirestore(), "settings", 'ftp'), this.data);
      this.saving = false
      this.done = true
      setTimeout(()=>{this.done = false}, 2000);
    }
  },
  async mounted(){
    const settingssnap = await getDoc(doc(getFirestore(), "settings", 'ftp'));
    if (settingssnap.exists()) {
      this.data = settingssnap.data()
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>