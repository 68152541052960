<template>
  <BreadCrumb/>
  <OverView/>
  <div class="container mt-3">
    <h3 class="text-center">Preise</h3>
    <div v-if="mailingData?.articleNumbers?.length > 0">
        <small v-if="mailingData.priceImport?.state == 'start'">Preisimport wurde initialisiert</small>
        <small v-else-if="mailingData.priceImport?.state === 'loading'">Preise werden geladen {{mailingData.priceImport?.count}}% <meter :value="mailingData.priceImport?.count" min="0" max="100"></meter></small>
        <small v-else-if="mailingData.priceImport?.state === 'done'">Letztes Update: {{returnDate(mailingData.priceImport.date?.toDate())}} Uhr</small>
      <div v-if="working || list.length <= 0 ">
        <Loading :msg="message"/>
      </div>
      <div v-else>
        <div class="scrollBox">
          <table class="table mb-0">
            <thead style="position:sticky; top: 0; background-color: white;">
              <tr>
                <th>Kundennummer</th>
                <th>Ländercode</th>
                <th>Währung</th>
                <th v-for="sku in listOfSKUs" :key="sku">{{sku}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item">
                <td>{{item.id}}</td>
                <td>{{item.country_no}}</td>
                <td>{{item.customer_currency}}</td>
                <td v-for="sku in listOfSKUs" :key="sku">{{item.prices[sku].toLocaleString(getLocale(item.customer_currency))}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col text-left d-flex justify-content-between mt-5 mb-5">
            <button class="btn btn-secondary" @click="this.$router.back()">Zurück</button>
            <button class="btn btn-secondary" @click="this.loadPrice()">Preise erneut Laden</button>
            <button class="btn btn-secondary" @click="this.$router.push({ name: 'Mailing vorschau mit Daten', params: { id: this.$route.params.id }})">Vorschau mit Preisen</button>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;" v-else>
      <p>Es müssen keine preise geladen werden.</p>
      <div class="row mt-3">
        <div class="col text-left">
          <button class="btn btn-secondary" @click="this.$router.push({ name: 'Testversand', params: { id: this.$route.params.id } })">Testversand</button>
        </div>
        <div class="col text-right">
          <button class="btn btn-secondary" @click="this.$router.push({ name: 'Versand', params: { id: this.$route.params.id } })">Versand</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../Loading.vue'
import { mapGetters } from "vuex";
import BreadCrumb from '../smallComponents/MailBreadCrumb.vue'
import OverView from '../smallComponents/MailOverview.vue'
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, collection, onSnapshot, addDoc } from "firebase/firestore";
import moment from 'moment-timezone'

export default {
  name: 'Preise',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  ...mapGetters({
      user: "user"
  }),
  components: {
    BreadCrumb,
    OverView,
    Loading
  },
  data(){
    return {
      working: true,
      message: 'Preise werden geladen.',
      list: [],
      listOfSKUs: [],
      mailingData: null,
    }
  },
  methods:{
    async loadData(){
      const mailing = await this.getData()
      !mailing.priceImport && await this.loadPrice()
      await this.getPricesFromDatabase()
      this.working = false
    },
    getLocale(input){
      return input === 'EUR'? 'de-DE': 'en-GB';
    },
    getData(){
      return new Promise((resolve, reject)=>{
         onSnapshot(doc(getFirestore(), "mailing", this.$route.params.id), (doc) => {
            if(doc.exists){
              (this.mailingData = doc.data()) && resolve(this.mailingData)
            } else {
              reject(false)
            }
          });
      })
    },
    async mailLog(action, id){
      let mailId = this.$route.params.id ? this.$route.params.id : id;
      const ref = await collection(getFirestore(), "mailing", mailId, "log");
      await addDoc(ref, {action: action, time: new Date()})
    },
    async loadPrice(){
      this.mailLog('Preisimport wurde ausgelöst.')
      const setLoadPrices = httpsCallable(getFunctions(this.firebase, this.region), 'setLoadPrices');
      await setLoadPrices({mailingId: this.$route.params.id, uid:this.$store.state.user.data.uid})
      .then(()=>{
        this.working = false
      })
      .catch(e=>{
        console.error(e)
        this.working = false
        this.error = true
      })
    },
    async getPricesFromDatabase(){
      await onSnapshot(collection(getFirestore(), "mailing", this.$route.params.id, 'prices'), (snap) => {
        this.list = []
        snap.forEach((doc) => {
          this.working = false
          let newObject = doc.data()
          newObject.id = doc.id
          // console.log(newObject)
          this.list.push(newObject)
        })
        this.listOfSKUs = this.list?.[0] ? Object.keys(this.list?.[0].prices) : []
      })   
    },
    returnDate(date){
      return moment(date).locale('de-DE').format('DD.MM.YYYY HH:mm')
    }
  },
  mounted(){
    this.loadData()
  },
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
  .scrollBox {
    border: 1px #f2f2f2 solid;
    max-height: 900px; 
    overflow-y: scroll;
  }
</style>