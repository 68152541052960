<template>
  <div class="container">
    <Loading v-if="working" />
    <div class="row" v-else>
      <div class="col">
        <h5 class="text-center mb-5 mt-5">Testversand Verteiler</h5>
        <div class="shadow-none p-3 mb-5 bg-light rounded">
          Hier werden alle Verteiler für den Testversand aufgelistet, die für den Testversand verwendet werden können.
          Es können neue Verteiler angelegt werden und Bestehende bearbeitet werden.
        </div>
        
        <div class="d-flex justify-content-start mb-3">
          <router-link  class="btn btn-success " :to="`/distributor/createtest`"> <font-awesome-icon :icon="['fas', 'plus']" size="1x"/> Testverteiler erstellen</router-link >
        </div>

        <input type="text" autocomplete="false" class="form-control mb-1" id="search" placeholder="Suchen" v-model="search">
        <div class="custombox">
          <table class="table mb-0">
            <thead>
              <tr>
                <th style="background-color: white;" class="sticky-top" scope="col">Name</th>
                <th style="background-color: white;" class="sticky-top" scope="col">E-Mail-Adressen</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in showPage" :key="index">
                <td>{{data.data.name}}</td>
                <td>{{data.data.addresses.length}}</td>
                <td>
                  <DropDown class="d-flex justify-content-end" :id="data.id" @delete="openDeleteModal" :test="true"/>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="btn-toolbar m-1 justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group" role="group" aria-label="First group">
              <template v-for="page in maxPages" :key="page">
                <template v-if="maxPages > 25 && page <= 10">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary ${usePage == page && 'active'}`" @click="usePage = page">{{page}}</button>
                </template>

                <template v-if="maxPages > 25 && page == 11 && usePage != 11 && page != (usePage - 1) && page != (usePage + 1)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary`" disabled @click="usePage = page">...</button>
                </template>

                <template v-if="maxPages > 25 && page > 10 && page < (maxPages - 9) && page >= (usePage - 1) && page <= (usePage + 1)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary ${usePage == page && 'active'}`" @click="usePage = page">{{page}}</button>
                </template>

                <template v-else-if="maxPages > 25 && page == Math.round(maxPages/2) && usePage < 9 || maxPages > 25 && page == Math.round(maxPages/2) && usePage > (maxPages - 9)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary`" @click="usePage = page">{{page}}</button>
                </template>

                <template v-if="maxPages > 25 && page == (maxPages - 10) && usePage != (maxPages - 10) && page != (usePage - 1) && page != (usePage + 1)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary`" disabled @click="usePage = page">...</button>
                </template>

                <template v-if="maxPages > 25 && page > (maxPages - 10)">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary ${usePage == page && 'active'}`" @click="usePage = page">{{page}}</button>
                </template>

                <template v-if="maxPages < 25">
                  <button type="button" :class="`btn btn-sm btn-outline-secondary ${usePage == page && 'active'}`" @click="usePage = page">{{page}}</button>
                </template>

              </template>
            </div>
            <div class="btn-group" role="group">
              <select class="form-select" style="width: 85px" v-model="maxPerPage" >
                <option selected :value="15">15</option>
                <option :value="30">30</option>
                <option :value="60">60</option>
                <option :value="100">100</option>
                <option :value="200">200</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" ref="modalDelete">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="toDelete">{{list.find(element => element.id === toDelete).data.name}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="toDelete">
            <p>Soll der Verteiler "<b>{{list.find(element => element.id === toDelete).data.name}}</b>" gelöscht werden?</p>
            <div class="alert alert-warning">Der Verteiler kann nach dem Löschen nicht wiederhergestellt werden.</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="color: white;" @click="deleteDistributor()">Löschen</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getFirestore, getDocs, query, collection, deleteDoc, doc } from "firebase/firestore"; 
import Loading from "../Loading.vue"
import DropDown from "../smallComponents/DropDown.vue"
import { Modal } from "@comlinedev/bootstrap5"

export default {
  name: 'card',
  components:{
    Loading,
    DropDown
  },
  data(){
    return{
      working: true,
      list: [],
      use: null,
      toDelete: false,
      deleteModal: null,
      err: null,
      success: null,
      search: '',
      usePage: 1,
      maxPerPage: 100
    }
  },
  computed:{
    tableDataFiltered(){
      return this.list.filter(data => {
        return data.data.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    maxPages(){
      return Math.ceil(this.tableDataFiltered.length / this.maxPerPage)
    },
    showPage(){
      const start = this.usePage * this.maxPerPage - this.maxPerPage
      const end = Number(start) + Number(this.maxPerPage)
      return this.tableDataFiltered.length > this.maxPerPage ? this.tableDataFiltered.slice(start, end) : this.tableDataFiltered
    }
  },
  methods:{
    async loadData(){
      const DistroburosRef = query(collection(getFirestore(), "testDistributor"));
      const querySnapshot = await getDocs(DistroburosRef);
      querySnapshot.forEach((doc) => {
        this.list.push({ id: doc.id, data: doc.data()})
      })

      return 'ok'
    },
    openDeleteModal(id){
      this.toDelete = id
      // console.log('Modal öffnen...')
      this.deleteModal.show()
    },
    deleteDistributor(){
      deleteDoc(doc(getFirestore(), "testDistributor", this.toDelete))
      .then(()=>{
        this.success
      })
      .catch(err=>{
        this.err = err
      })

    }
  },
  mounted(){
    this.loadData()
    .then(()=>{
      this.working = false
    })
    this.deleteModal = new Modal(this.$refs.modalDelete)
  },
}
</script>

<style scoped>
  .custombox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
  }
</style>
