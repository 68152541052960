<template>
  <div class="">
    <h5 class="text-center mt-5 mb-5">{{ $t("message.signup.title") }}</h5>
    <transition name="fade">
      <Loading v-if="working && !done" />
    </transition>
    <transition name="fade">
      <div v-if="done">
        <div class="container">
          <div class="alert alert-success" role="alert">
            <p>{{$t('message.signup.successMessage')}}</p>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="container" v-if="!working && !done">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <form class="row g-3" @submit.prevent="checkForm">
              <div class="mb-3">
                <label for="email" class="form-label">{{$t('message.signup.email')}}</label>
                <input type="email" class="form-control" id="email" :placeholder="$t('message.signup.email')" v-model="email">
              </div>

              <div class="mb-3">              
                <label for="salutation" class="form-label">{{$t('message.signup.salutation')}}</label>
                <select class="form-select" id="salutation" @change="setSalutation($event)">
                  <option selected disabled>{{$t('message.signup.selectsalutation')}}</option>
                  <option value="herr">{{$t('message.signup.mr')}}</option>
                  <option value="frau">{{$t('message.signup.mrs')}}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="firstname" class="form-label">{{$t('message.signup.firstname')}}</label>
                <input type="firstname" class="form-control" id="firstname" :placeholder="$t('message.signup.firstname')" v-model="firstname">
              </div>

              <div class="mb-3">
                <label for="lastname" class="form-label">{{$t('message.signup.lastname')}}</label>
                <input type="lastname" class="form-control" id="lastname" :placeholder="$t('message.signup.lastname')" v-model="lastname">
              </div>

              <div class="mb-3">
                <label for="customernumber" class="form-label">{{$t('message.signup.customernumber')}}</label>
                <input type="customernumber" class="form-control" id="customernumber" :placeholder="$t('message.signup.customernumber')" v-model="customernumber">
              </div>

              <div class="mb-3">
                <label for="company" class="form-label">{{$t('message.signup.company')}}</label>
                <input type="company" class="form-control" id="company" :placeholder="$t('message.signup.company')" v-model="company">
              </div>

              <div class="mb-3" >
                <p class="mb-0 mb-2">{{$t('message.preferencecenter.mydistributors')}}</p>
                <ul class="list-group" v-if="allCategories" >
                  <li class="list-group-item" v-for="category in allCategories" :key="category.id">
                    <div class="form-check form-switch">
                      <label class="clickable" :for="category.id">{{category.name[this.$route.params.lang]}}</label>
                      <input class="form-check-input" type="checkbox" :id="category.id" :value="category.id" v-model="selected">
                    </div>
                  </li>
                </ul>
                <Loading v-else/>
              </div>


              <transition name="fade">
                <div  v-if="errors.length > 0" class="mb-3">
                  <div class="alert alert-danger" role="alert">
                    <ul>
                      <li v-if="errors.includes('email')">{{this.$t('message.signup.errors.email')}}</li>
                      <li v-if="errors.includes('salutation')">{{this.$t('message.signup.errors.salutation')}}</li>
                      <li v-if="errors.includes('firstname')">{{this.$t('message.signup.errors.firstname')}}</li>
                      <li v-if="errors.includes('lastname')">{{this.$t('message.signup.errors.lastname')}}</li>
                      <li v-if="errors.includes('customernumber')">{{this.$t('message.signup.errors.customernumber')}}</li>
                      <li v-if="errors.includes('company')">{{this.$t('message.signup.errors.company')}}</li>
                      <li v-if="errors.includes('dsgvo')">{{this.$t('message.signup.errors.dsgvo')}}</li>
                    </ul>
                  </div>
                </div>
              </transition>

              <div class="mb-3" >
                <input class="form-check-input" type="checkbox" v-model="dsgvoCheck" id="DSGVO">
                <label class="form-check-label ms-1" for="DSGVO" id="dsgvo">
                  {{this.$t('message.signup.dsgvo-1')}} <a :href="this.$t('message.signup.dsgvoLink')" target="_blank"> {{this.$t('message.signup.dsgvo-2')}}</a>
                </label>
              </div>

              <div class="mt-3 mb-5 text-center">
                <SaveButton :saving="saving" :text="$t('message.signup.save')" :done="done" class="mb-3" />
                <a :href="`/getaccess/${this.$route.params.lang}`" >{{this.$t('message.signup.iAmSubscriber')}}</a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </transition> 
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, getFirestore, addDoc } from "firebase/firestore"
import Loading from "../Loading.vue"
import SaveButton from "../smallComponents/SaveButton.vue"
import detectBrowserLanguage from 'detect-browser-language'
import EmailValidator from 'email-validator';

export default {
  name: 'SingUp',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  components:{
    Loading,
    SaveButton
  },
  watch: {
      email: function (val) {
        const isMail = EmailValidator.validate(val) 
        console.log(val, isMail)
        if(isMail){
          let index = this.errors.indexOf('email')
          console.log(index)
          if (index > -1) {
            console.log('OK')
            console.log(this.errors)
            this.errors.splice(index, 1);
            console.log(this.errors)
          }
        }
      },
      salutation: function (val) {
        if(val){
          let index = this.errors.indexOf('salutation')
          if (index > -1) {
            this.errors.splice(index, 1);
          }
        }
      },
      lastname: function (val) {
        if(val){
          let index = this.errors.indexOf('lastname')
          if (index > -1) {
            this.errors.splice(index, 1);
          }
        }
      },
      firstname: function (val) {
        if(val){
          let index = this.errors.indexOf('firstname')
          if (index > -1) {
            this.errors.splice(index, 1);
          }
        }
      },
      customernumber: function (val) {
        if(val){
          let index = this.errors.indexOf('customernumber')
          if (index > -1) {
            this.errors.splice(index, 1);
          }
        }
      },
      company: function (val) {
        if(val){
          let index = this.errors.indexOf('company')
          if (index > -1) {
            this.errors.splice(index, 1);
          }
        }
      },
      dsgvoCheck: function (val) {
        let index = this.errors.indexOf('dsgvo')
        if(val && index > -1){
          this.errors.splice(index, 1);
        }
      }
  },
  data(){
    return {
      email: null,
      lastname: null,
      firstname: null,
      customernumber: null,
      salutation: undefined,
      company: null,
      errors: [],
      selected: [],
      working: false,
      done: false,
      saving: false,
      allCategories: false,
      dsgvoCheck: false,
      detectBrowserLanguage: detectBrowserLanguage()
    }
  },
  methods:{
    checkForm(e){
      e.preventDefault();
      if(this.email && this.lastname && this.firstname && this.customernumber && this.salutation && this.company && EmailValidator.validate(this.email)) {
        this.saveData()
      }
      else {
        this.errors = []
        !EmailValidator.validate(this.email) && this.errors.push('email')
        !this.salutation && this.errors.push('salutation')
        !this.firstname && this.errors.push('firstname')
        !this.lastname && this.errors.push('lastname')
        !this.customernumber && this.errors.push('customernumber')
        !this.company && this.errors.push('company')
        !this.dsgvoCheck && this.errors.push('dsgvo')
      }
    },
    async saveData(){
      this.saving = true
      await addDoc(collection(getFirestore(), "subscribersPending"), {
        email: this.email,
        lastname: this.lastname,
        firstname: this.firstname,
        customernumber: this.customernumber,
        salutation: this.salutation,
        company: this.company,
        lang: this.$route.params.lang,
        selected: this.selected
      });
      this.saving = false
        this.done = true
      // console.log(result)
    },
    async distributorRequest(){
      let getCategories = await httpsCallable(getFunctions(this.firebase, this.region), 'getCategories');
      await getCategories()
      .then((data)=>{
        // console.log(data.data)
        this.allCategories = data.data
      })
      .catch(err=>{      
        console.error(err)
      })
    },
    setSalutation(event){
      this.salutation = event.target.value
    },
  },
  mounted(){
    this.distributorRequest()
    this.$router.push(detectBrowserLanguage().split('-')[0])
  }
}
</script>

<style scoped>
  #dsgvo a {
    color: black;
    /* text-decoration: none; */
  }
  .fade-enter-active,
  .fade-leave-active {
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.25s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.25s ease;
  }
</style>