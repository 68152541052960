import { createRouter, createWebHistory } from "vue-router";

import Home from "../../components/Home"
import Login from "../../components/Login"
import store from "../store";

//Maling
import MailingFirstSite from "../../components/mailings/FirstSite"
import GetListOfRecipients from "../../components/mailings/GetListOfRecipients"
import MailingPreView from "../../components/mailings/Preview"
import Linklist from "../../components/mailings/Linklist"
import Priceloader from "../../components/mailings/Priceloader"
import MailingPreViewFilled from "../../components/mailings/PreviewFilled"
import TestDelivery from "../../components/mailings/TestDelivery"
import Delivery from "../../components/mailings/Delivery"
import MailingCreate from "../../components/mailings/Create"
import MailingList from "../../components/mailings/List"
import MailingEdit from "../../components/mailings/EditGeneralData"
import SingleDelivery from "../../components/mailings/SingleDelivery"
import ShowMailing from "../../components/mailings/ShowMailing"
import MailInfosBeforDelivery from "../../components/mailings/MailingInfoBeforeSending"

//Evualation
import MailingInfoList from "../../components/evaluation/EvaluationList"
import Evaluation from "../../components/evaluation/Evaluation"
import MailingInfo from "../../components/evaluation/MailingInfo"

//distributor
import ListTest from "../../components/distributor/ListTest.vue"
import CategoryEdit from "../../components/distributor/EditCategories.vue"
import TestDistributorCreate from "../../components/distributor/CreateTest"
import TestDistributorEdit from "../../components/distributor/EditTestDelivery"

import NotAllowed from "../../components/NotAllowed"

//Settings
import Settings from "../../components/Settings"

//Doku
import Doku from "../../components/Doku.vue"

//Report
import Report from "../../components/Report.vue"

//404
import NotFound from "../../components/NotFound"

//Public
import SignUp from "../../components/public/Signup"
import PreferenceCenter from "../../components/public/PreferenceCenter"
import Success from "../../components/public/Success"
import GetAccess from "../../components/public/getAccess"

import i18n from "../i18n";

import { auth } from "../firebase/index";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { 
      path: '/:pathMatch(.*)*', 
      name: 'NotFound', 
      component: NotFound 
    },
    {
      path: "/notallowed",
      component: NotAllowed,
      name: "NotAllowed",
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      }
    },
    {
      path: "/admin",
      component: Home,
      name: "Home",
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      }
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
      meta: {
        requiresAuth: false,
        notForOnlyReport: false
      }
    },
    {
      path: "/token/:token",
      component: Login,
      name: "Token",
      meta: {
        requiresAuth: false,
        notForOnlyReport: true
      }
    },
    {
      path: "/mailing",
      component: MailingFirstSite,
      name: "Mailing",
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      }
    },
    {
      path: "/report",
      component: Report,
      name: "Report",
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      }
    },
    {
      path: '/mailing/create',
      name: "Mailing erstellen",
      component: MailingCreate,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/general/:id',
      name: "Edit Mailing",
      component: MailingEdit,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/recipients/:id',
      name: "Mailing empfaenger",
      component: GetListOfRecipients,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/preview/:id',
      name: "Mailing vorschau",
      component: MailingPreView,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/linklist/:id',
      name: "Link vorschau",
      component: Linklist,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/priceloader/:id',
      name: "Preis vorschau",
      component: Priceloader,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/previewfilled/:id',
      name: "Mailing vorschau mit Daten",
      component: MailingPreViewFilled,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/showmailing/:id/:subscriber',
      name: "Mailing-HTML",
      component: ShowMailing,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/testdelivery/:id',
      name: "Testversand",
      component: TestDelivery,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/delivery/:id',
      name: "Versand",
      component: Delivery,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/list',
      name: "Mailing bearbeiten",
      component: MailingList,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/singledelivery/:id',
      name: "Einzelversand",
      component: SingleDelivery,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/mailing/mailinfotest/:id',
      name: "Infoseite",
      component: MailInfosBeforDelivery,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      }
    },
    {
      path: '/mailinfo',
      name: "MailingInfoList Liste",
      component: MailingInfoList,
      meta: {
        requiresAuth: true,
        notForOnlyReport: false
      },
    },
    {
      path: '/mailinfo/evaluation/:id',
      name: "Auswertung",
      component: Evaluation,
      meta: {
        requiresAuth: true,
        notForOnlyReport: false
      },
    },
    {
      path: '/mailinfo/:id',
      name: "MailingInfo",
      component: MailingInfo,
      meta: {
        requiresAuth: true,
        notForOnlyReport: false
      },
    },
    {
      path: '/distributor/editcategorys',
      name: "Kategorien bearbeiten",
      component: CategoryEdit,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/distributor/listtest',
      name: "Test Distributor",
      component: ListTest,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/distributor/edittest/:id',
      name: "Testverteiler bearbeiten",
      component: TestDistributorEdit,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/distributor/createtest',
      name: "Testverteiler erstellen",
      component: TestDistributorCreate,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/dokumentation',
      name: "Dokumentation",
      component: Doku,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    {
      path: '/settings',
      name: "Settings",
      component: Settings,
      meta: {
        requiresAuth: true,
        notForOnlyReport: true
      },
    },
    //public
    {
      path: "/",
      redirect: '/getaccess/de'
    },
    {
      path: '/getaccess',
      redirect: '/getaccess/de'
    },
    {
      path: '/getaccess',
      redirect: '/getaccess/de'
    },
    {
      path: '/signup/:lang',
      name: "SignUp",
      component: SignUp,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/preferences/:token/:id/:lang',
      name: "Preferences",
      component: PreferenceCenter,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/success/:lang',
      name: "Success",
      component: Success,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/getaccess/:lang',
      name: "GetAccess",
      component: GetAccess,
      meta: {
        requiresAuth: false
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {

  let lang = to.params.lang;
  if (!lang) {
    lang = 'en'
  }
  i18n.global.locale = lang

  const currentUser = store.state.user.loggedIn

  let tokens = currentUser ? await auth.currentUser.getIdTokenResult() : false
  let isUser = tokens ? tokens.claims?.user : false
  let onlyReport = tokens ? tokens.claims?.onlyReport : false
  // let isDev = tokens ? tokens.claims?.developer : false

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const notForOnlyReport = to.matched.some(record => record.meta.notForOnlyReport)

  if ((to.name === 'Login' || to.name === 'NotAllowed') && currentUser && isUser) next({ name: 'Home' })
  else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) next({ name: 'NotAllowed' })
  else if (isUser && currentUser && requiresAuth && onlyReport && notForOnlyReport) next({ name: 'MailingInfoList Liste' })
  else if (requiresAuth && currentUser) next()
  else if (requiresAuth && !currentUser) next({ name: 'Login' })
  else next()
})

export default router;