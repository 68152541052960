<template>
  <div class="col text-left">
    <h3>Template</h3>
    <p></p>
    <br>
    <h4>Aufbau</h4>
    <p>Das Template muss auf dem FTP Server in den Ordner Newsletter abgelegt werden.</p>
    <p>Alle Datein müssen in einem Eindeutig benannten Order sein.</p>
    <p>Jedes template muss eine index.htm oder index.html und eine inex.txt beinhalten.</p>
    <p>Die Bilder können innerhalb dieses Templateordners in einem Ordner gesammelt liegen oder lose im root des Templateordners.</p>
    <br>
    
    <h4>Versand</h4>
    <p>Bei Mailings bei den Artikelnummern im Template gefunden wurden muss vor dem Versand ein Preisimport erfolgen. Vor dem Versand werden zusätzlich nochmal für jede Kundennummer alle Preise geladen.</p>
    <p>Jedes Mailing kann nur einmal versendet werden.</p>
    <br>

    <h4>Links</h4>
    <p>Alle Links können als Direktlinks eingetragen werden. Sie werden später durch Trackinglinks ersetzt.</p>
    <br>

    <h4>Bilder</h4>
    <p>Für den Bildpfad dient der Templateordner immer als root verzeichnis.</p>
    <p>Liegt ein Bild direkt in dem Templateordner kann für die verwendung einfach direkt der Name <code>bild.png</code> verwendet werden oder aber auch <code>./bild.png</code>.</p>
    <p>Liegt das Bild in einem Ordner muss dieser mit angegeben werden. Angenommen das Bild liegt in dem Order "img" ist der Pfad zum Bild <code>img/bild.png</code> oder <code>./img.bild.png</code></p>
    <br>

  </div>
</template>

<script>
export default {
  name: 'dokuTemplate'
}
</script>

<style scoped>
  code {
    border: 1px #ced4da solid;
    border-radius: .25rem;
    padding: 5px;
  }
</style>