<template>
  <div id="card">
    <Loading v-if="working" />
    <template v-else>
      <input type="text" autocomplete="false" class="form-control mb-1" id="search" placeholder="Suchen" v-model="search">
      <div class="scrollBox">
        <table class="table mb-0">
          <tbody  v-if="tableDataFiltered.length > 0">
            <tr v-for="(data, index) in tableDataFiltered || []" :key="index" :class="use === data.id && 'table-light'">
              <td><label :for="`keywords-${data}-${get}`">{{data}}</label></td>
              <td>
                <div class="form-check form-switch d-flex justify-content-end">
                  <input class="form-check-input" :id="`keywords-${data}-${get}`" type="checkbox" :value="true" v-model="use[data]" @change="addOrRemove">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex"> 
        <p>Ausgewählt:&nbsp;</p> <div v-for="(name, index) in Object.keys(use) || []" :key="`inUse-${index}`">
        <b>{{name}}</b><span v-show="Object.keys(use).length > index+1 && Object.keys(use).length != index+1">, &nbsp;</span>
      </div>
    </div>
    </template>
  </div>
</template>

<script>

import { httpsCallable, getFunctions } from "firebase/functions";
import { firebase, region } from "../../plugins/firebase";
import Loading from "../Loading.vue"
import {toRaw} from "vue"

export default {
  name: 'card',
  components:{
    Loading
  },
  emits: ['selectChanged'],
  props: {
    get: String, 
    preSelected: Object
  },
  watch: {
    preSelected: {
      handler: function() {
      toRaw(this.preSelected).forEach((el)=>this.use[el] = true)
      },
      deep: true
    },
    use: {
      handler: function() {
      Object.entries(this.use).forEach(each => {each[1] == false && delete this.use[each[0]];});
      this.$emit("selectChanged", {data: Object.keys(this.use), get: this.get})
      return null;
    },
    deep: true
    }
  },
  data(){
    return{
      keywords: [],
      working: true,
      use: {},
      search: ''
    }
  },
  computed:{
    tableDataFiltered(){
      return this.keywords.filter(data => {
        return data.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods:{
    async loadData(){
      let getPersonKeywords = await httpsCallable(getFunctions(firebase, region), 'getPersonKeywords');
      let getCustomerKeywords = await httpsCallable(getFunctions(firebase, region), 'getCustomerKeywords');

      if(this.get === "person"){
        await getPersonKeywords()
        .then((data)=>{
          this.keywords = data.data
        })
        .catch(err=>{ 
          console.error(err)
        })
      } else {
        await getCustomerKeywords()
        .then((data)=>{
          this.keywords = data.data
        })
        .catch(err=>{ 
          console.error(err)
        })
      }

      return 'ok'
    }
  },
  mounted(){
    this.loadData()
    .then(()=>{
      this.working = false
    })
    .catch(err=> console.error(err))
  },
}
</script>

<style scoped>
  #card {
    cursor: pointer;
  }
  .scrollBox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
    max-height: 300px; 
    overflow-y: scroll;
  }
</style>
