<script>
import { defineComponent } from 'vue'
import { Pie } from 'vue3-chart-v2'
// import extractDomain from "extract-domain";

export default defineComponent({
  name: 'CircleChart',
  extends: Pie,
  props:{
    labels: Array,
    dataSets: Array
  },
  mounted () {
    // console.log(this.dataSets)
    this.renderChart(
    {//data
      labels: this.labels,
      datasets: this.dataSets,
    // }
    // {//options
    //     scales: {
    //       xAxes: [{
    //       }],
    //       yAxes: [{
    //         ticks: {
    //               callback: function (value) {
    //                   const domain = extractDomain(value)
    //                   // if(domain === 'comline-shop.de'){}
    //                   return domain
    //               },
    //           }
    //       }]
    //     },
    })
  }
})
</script>