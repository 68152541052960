<template>
  <div class="container d-flex justify-content-center mb-2 mt-2">
    <Loading v-if="!data"/>
    <div class="box" v-else>
      <div class=" d-flex justify-content-center">
        <h4>{{data?.name}}</h4>
      </div>
      <div class="d-flex justify-content-center">
        <a href="#" v-if="!show" @click="show=!show" ><font-awesome-icon :icon="['fas', 'angle-down']" size="1x" /></a>
        <a href="#" v-if="show" @click="show=!show" ><font-awesome-icon :icon="['fas', 'angle-up']" size="1x" /></a>
      </div>
      <span v-if="show">
        <div class=" d-flex justify-content-center">
          <p>{{data?.description}}</p>
        </div>
        <div class="row" v-if="show">
          <div class="col">
            <p>Template: {{data?.mailing}}</p>
            <p>Erstellt: {{convertDate(data?.timestamp)}}</p>
            <p v-if="Array.isArray(data?.articleNumbers)">Artikelnummern: {{data?.articleNumbers.length}}</p>
            <p v-if="data?.sendTestmail">Letzter Testversand: {{convertDate(data?.sendTestmail)}}</p>
            <p>Preisimport: {{analysePriceImport(data?.priceImport)}}</p>
            <p>Absender: {{data?.sender?.name}} &lt;{{data?.sender?.email}}&gt;</p>
          </div>
          <div class="col">
            <template  v-if="data.deluxeFilter">
              <p>Sprache: <b>{{data?.deluxeFilter?.language || "Keine"}}</b></p>
              <p>Kunden-Stichworte [and]: <span v-for="(word, index) in data?.deluxeFilter?.customerKeywords?.and" :key="index"><b>{{word}}</b>, </span></p>
              <p>Kunden-Stichworte [or]: <span v-for="(word, index) in data?.deluxeFilter?.customerKeywords?.or" :key="index"><b>{{word}}</b>, </span></p>
              <p>Personen-Stichworte [or]: <span v-for="(word, index) in data?.deluxeFilter?.personKeywords?.or" :key="index"><b>{{word}}</b>, </span></p>
              <p>Empfänger: <b>{{data?.recipientListFetchedData?.number_of_recipients}}</b></p>

            </template>
          </div>
        </div>
      </span>
    </div>
  </div> 
  <div class="container">
    <div class="alert alert-danger" v-if="checkedSubscribers.length > 0">
      <p>Es {{checkedSubscribers.length > 1 ? 'sind' : 'ist'}} {{checkedSubscribers.length}} Abonenten in dem Verteiler mit Unvollständigen Daten oder falscher E-Mail-Adresse</p>
      <button class="btn btn-primary btn-sm text-center" @click="showCheckedUser=!showCheckedUser">{{showCheckedUser?'Ausblenden':'Anzeigen'}}</button>
      <ul style="list-style-type: none;" class="text-center" v-if="showCheckedUser">
        <li v-for="(each, index) in checkedSubscribers" :key="index" class="">
          <p>{{each.email}} <b>PersonId:</b> {{each.personId}} <b>Kundennummer:</b> {{each.customerNumber}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { getFirestore, doc, getDoc, query, collection, onSnapshot, where} from "firebase/firestore";
import { getFirestore, doc, getDoc} from "firebase/firestore";
import Loading from '../Loading.vue'
// import EmailValidator from 'email-validator';
// import Info from '../smallComponents/Info.vue'

export default {
  name: 'Overview',
  components: {
    Loading,
    // Info
  },
  data(){
    return {
      show: true,
      loading: true,
      data: null,
      subscribers: [],
      nutzerbereinigtCount: 0,
      checkedSubscribers: [],
      showCheckedUser: false
    }
  },
  methods: {
    async getMailingData(){
      const docSnap = await getDoc(doc(getFirestore(), "mailing", this.$route.params.id ));
      if (docSnap.exists()) {
        this.data = docSnap.data()
      } else {
        console.error("No such document!");
      }
    },
    // async checkSubscribers(){
    //   const subscribers = this.data?.recipientListPersonIds
    //   if(subscribers){
    //     for await (let subscriber of subscribers) {
    //       await this.checkSubscriberData(subscriber)
    //     }
    //   }
    // },
    // async checkSubscriberData(subsciberId){
    //   let subscriber = false
    //   const q = await query(collection(getFirestore(), "subscribers"), where("personId", "==", subsciberId));
    //   await onSnapshot(q, (querySnapshot) => {
    //     if(querySnapshot.docs.length > 0) {

    //       let TempData = querySnapshot.docs[0].data()
    //       if(TempData.firstName == "" || TempData.lastName == "" || TempData.company == "" || TempData.lang == "" || TempData.salutation == "" || !EmailValidator.validate(TempData.email) || TempData.shopLang == false){
    //         subscriber = TempData
    //         subscriber.id = querySnapshot.docs[0].id
    //         this.checkedSubscribers.push(subscriber)
    //       }
    //     }
    //   })
    // },
    analysePriceImport(data){
      // console.log(data)
      return this.convertDate(data?.date)
    },
    convertDate(date){
      if(!date){return '-'}
      return `${date.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })} ${date.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})} Uhr`
    },
  },
  async mounted(){
    await this.getMailingData()
    // await this.checkSubscribers()
    this.loading = false
  },
}
</script>

<style scoped>
  .box {
    width: 100%;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    padding: 15px;
  }
  a {
    color: black; 
  }
  .fade-enter-active,
  .fade-leave-active {
    transform: scaleY(1);
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.25s ease;
  }
</style>
