// import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler.js" //temp Problem???
import { createI18n } from 'vue-i18n';

function loadLocaleMessages () {
  const translations = require.context('../../translations', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  translations.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = translations(key)
    }
  })
  return messages
}

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})

export default i18n