<template>
  <div class="">
    <h5 class="text-center mt-5 mb-5">Mailing erstellen</h5>
    <div class="container">
      <div class="shadow-none p-3 mb-5 bg-light rounded">
        Hier kann eine neues Mailing erstellt werden.
      </div>
    </div>
    <transition name="fade">
      <Loading v-if="working && !done" />
    </transition>
    <transition name="fade">
      <div v-if="done">
        <div class="container">
          <div class="alert alert-success" role="alert">
            <p>Mailing wurde erstellt.</p>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="container" v-if="!working && !done">
        <div class="row">
          <div class="col">

            <form class="row g-3" @submit.prevent="checkForm">
              <div class="mb-3">
                <label for="name" class="form-label">Name / Betreff</label><Info class="ms-2" content="Der Name Enspricht dem Betreff beim Versand."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
                <input type="text" class="form-control" id="name" placeholder="Mailingname" v-model="name">
              </div>

              <div class="mb-3">
                <label for="description" class="form-label">Notizen</label><Info class="ms-2" content="Die Notizen sind optional, und nur für interne Zwecke."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
                <textarea class="form-control" id="description" rows="3" v-model="description"></textarea>
              </div>

              <div class="mb-3">
                <select class="form-select" aria-label="Default select example" @change="setSender($event)">
                  <option selected disabled>Wähle einen Absender oder Trage einen ein.</option>
                  <option v-for="(sender, index) in senders" :key="index" :value="index">{{sender.name}}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="senderName" class="form-label">Absender Name</label>
                <input type="text" class="form-control" placeholder="Absender" v-model="sender.name" aria-label="senderName">
              </div>

              <div class="mb-3">
                <label for="senderEmail" class="form-label">Absender E-Mail-Adresse</label>
                <input type="email" class="form-control" placeholder="E-Mail-Adresse" v-model="sender.email" aria-label="senderEmail">
              </div>

              <div class="mb-3">
                <label class="form-label">Template</label><Info class="ms-2" content="Wähle das Template, welches du auf den FTP-Server geladen hast."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
                <SelectMailing @selectMailingChanged="selectMailingChanged" />
              </div>

              <div class="mb-3 text-left">
                <p>Artikelnummern <Info class="ms-2" content="Die Artikelnummern werden automatisch aus dem Template / Mailing geladen. Wähle das Template und warte bis der Prozess abgeschlossen ist."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info></p>
                <div v-if="articleNumbers && Array.isArray(articleNumbers)">
                  <ul>
                    <li v-for="el in articleNumbers" :key="el">{{el}}</li>
                  </ul>
                </div>

                <div v-else-if="articleNumbers">
                  <Loading :msg="articleNumbers"/>
                </div>
                
                <div v-else>
                  Keine Artikelnummern gefunden.
                </div>
              </div>
<!-- 
              <div class="mb-3">
                <label class="form-label">Verteiler</label><Info class="ms-2" content="Wähle die Verteiler, an die die das Mailing später versendet werden soll."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
                <SelectDistributor @selectDistibutorChanged="selectDistibutorChanged" />
              </div> -->

              <transition name="fade">
                <div  v-if="errors.length > 0" class="mb-3">
                  <div class="alert alert-danger" role="alert">
                    <ul>
                      <li v-for="error in errors" :key="error">{{error}}</li>
                    </ul>
                  </div>
                </div>
              </transition>

              <div class="col-auto ">
                <button type="submit" class="btn btn-primary mb-5" :disabled="!Array.isArray(articleNumbers)">Erstellen</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getFirestore, collection, query, getDocs, addDoc } from "firebase/firestore";
// import SelectDistributor from "../smallComponents/SelectAllDistributor.vue"
import SelectMailing from "../smallComponents/SelectMailing.vue"
import Loading from '../Loading.vue'
import { getFunctions, httpsCallable } from "firebase/functions";
import Info from '../smallComponents/Info.vue'

export default {
  name: 'CreateMailing',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  emits: ['selectDistibutorChanged'],
  components:{
    // SelectDistributor,
    SelectMailing,
    Loading,
    Info
  },
  watch: {
    name: function (val) {
      if(val){
        let index = this.name.indexOf(this.erroMessages.name)
        if (index > -1) {
          this.errors.splice(index, 1);
        }
      }
    },
  },
  data(){
    return {
      name: null,
      description: null,
      selectedDistibutor: null,
      selectedMailing: null,
      articleNumbers: null,
      senders: [],
      sender: {email: "marketing@comline-shop.de", name: "ComLine GmbH"},
      selecte: [],
      errors: [],
      erroMessages: {
        name: 'Bitte fülle das Feld Mailingname aus.',
        selectedDistibutor: 'Bitte wähle einen Verteiler.',
        sender: 'Bitte fülle die Sender-Daten aus.',
        selectedMailing: 'Bitte wähle ein Mailing.'
      },
      working: false,
      done: false,
    }
  },
  methods:{
    async mailLog(action, id){
      let mailId = this.$route.params.id ? this.$route.params.id : id;
      const ref = await collection(getFirestore(), "mailing", mailId, "log");
      await addDoc(ref, {action: action, time: new Date()})
    },
    selectMailingChanged(val){
      this.errors = []
      this.selectedMailing = val
      this.articleNumbers = 'Artikelnummern werden geladen.'
      const getTemplates = httpsCallable(getFunctions(this.firebase, this.region), 'getArticleNumbers');
        getTemplates(val)
        .then(res => {
          if (Array.isArray(res.data) && res.data.length > 0) {
            this.articleNumbers = res.data
          } else {
            this.articleNumbers = Array.isArray(res.data) ? res.data : null
            !Array.isArray(res.data) && this.errors.push(res.data)
          } 
        })
        .catch(e => {
          console.error(e)
          this.errors.push(e)
        })
    },
    checkForm(e){
      e.preventDefault();
      if(this.name && this.selectedMailing ) {
        this.saveData()
      }
      else {
        this.errors = []
        !this.name && this.errors.push(this.erroMessages.name)
        !this.selectedDistibutor && this.errors.push(this.erroMessages.selectedDistibutor)
        !(this.sender.name && this.sender.email) && this.errors.push(this.erroMessages.sender)
        !this.selectedMailing && this.errors.push(this.erroMessages.selectedMailing)
      }
    },
    async saveData(){

      this.working = true
      const data = {
        timestamp: new Date(),
        name: this.name,
        description: this.description,
        mailing: this.selectedMailing,
        sender: this.sender,
        articleNumbers: this.articleNumbers,
        priceImport: false
      }

      let result = await addDoc(collection(getFirestore(), "mailing"), data);
      this.mailLog('Mailing wurde angelgt',result.id)
      this.$router.push({ name: 'Mailing empfaenger', params: { id: result.id } })
    },
    setSender(event){
      this.sender = this.senders[event.target.value]
    },
    async getSender(){
      const q = query(collection(getFirestore(), "sender"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.senders.push(doc.data())
      })
    },
  },
  async mounted(){
    await this.getSender()
  }
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
  .fade-enter-active,
  .fade-leave-active {
    transform: scaleY(1);
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.25s ease;
  }
</style>