<template>
  <div class="">
    <h5 class="text-center mt-5 mb-5">{{ $t("message.getAccess.title") }}</h5>
    <transition name="fade">
      <Loading v-if="working && !done" />
    </transition>
    <transition name="fade">
      <div v-if="done">
        <div class="container">
          <div class="alert alert-success" role="alert">
            <p>{{$t('message.getAccess.successMessage')}}</p>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="container" v-if="!working && !done">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <form class="row g-3" @submit.prevent="checkForm">
              <div class="mb-3">
                <label for="email" class="form-label">{{$t('message.getAccess.email')}}</label>
                <input type="email" class="form-control" id="email" :placeholder="$t('message.getAccess.email')" v-model="email">
              </div>

              <transition name="fade">
                <div  v-if="errors.length > 0" class="mb-3">
                  <div class="alert alert-danger" role="alert">
                    <ul>
                      <li v-if="errors.includes('email')">{{this.$t('message.getAccess.errors.email')}}</li>
                    </ul>
                  </div>
                </div>
              </transition>

              <div class="mt-3 mb-5 text-center">
                <SaveButton :saving="saving" :text="$t('message.getAccess.save')" :done="done"/>
              </div>

            </form>
          </div>
        </div>
      </div>
    </transition> 
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import Loading from "../Loading.vue"
import SaveButton from "../smallComponents/SaveButton.vue"
import detectBrowserLanguage from 'detect-browser-language'

export default {
  name: 'SingUp',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  components:{
    Loading,
    SaveButton
  },
  watch: {
    email: function (val) {
      if(val){
        let index = this.errors.indexOf('email')
        if (index > -1) {
          this.errors.splice(index, 1);
        }
      }
    },
  },
  data(){
    return {
      email: null,
      errors: [],
      working: false,
      done: false,
      saving: false,
      detectBrowserLanguage: detectBrowserLanguage()
    }
  },
  methods:{
    checkForm(e){
      e.preventDefault();
      if(this.email) {
        this.saveData()
      }
      else {
        this.errors = []
        !this.email && this.errors.push('email')
      }
    },
    async saveData(){
      this.saving = true

      let getAccess = await httpsCallable(getFunctions(this.firebase, this.region), 'getAccess');
      await getAccess({email: this.email, lang: this.$route.params.lang})
      .catch(err=>{      
        console.error(err)
      })

      this.saving = false
      this.done = true

    },
  },
  mounted(){

  }
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
  .fade-enter-active,
  .fade-leave-active {
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.25s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.25s ease;
  }
</style>