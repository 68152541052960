<template>
  <div id="card">
    <Loader v-if="working" :color="'gray'" size="5px" />
    <div v-else>
      <font-awesome-icon v-if="check" color="green;" :icon="['fas', 'check']" />
      <font-awesome-icon v-else color="red;" :icon="['fas', 'times']" />
    </div>
  </div>
</template>

<script>
import Loader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'card',
  components:{
    Loader
  },
  props:{
    url: String,
    toReturn: String
  },
  watch: {

  },
  emits: ['selectUserChange'],
  data(){
    return{
      working: true,
      check: null
    }
  },
  methods:{
    async checkReachable(){
      const isReachable = require('is-reachable');
      const checked = await isReachable(this.url)
      if(checked){
        this.check = checked
        // console.log(checked)
        this.working = false
      }else {
        this.working = false
      }
    },
    validateURL() {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      this.check = pattern.test(this.url);
      // console.log(this.check)
      this.working = false
    },
  },
  mounted(){
    this.toReturn === 'Reachable' && this.checkReachable()
    this.toReturn === 'validateURL' && this.validateURL()
  },
}
</script>

<style>
  #card {
    cursor: pointer;
  }
  .scrollBox {
    max-height: 300px; 
    overflow-y: scroll;
  }
</style>
