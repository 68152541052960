<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-primary" style=" color: white;">
    <div class="container">
      
      <a class="navbar-brand d-flex" href="/admin" v-if="user?.loggedIn && $route.meta.requiresAuth">
        <Logo :fill="'#ffffff'" :height="'33px'" :width="'33px'" class="mb-0 me-2 d-flex align-items-center"></Logo> <span id="logotext"> Newsletter</span>
      </a>          
      <a class="navbar-brand d-flex" v-else>
        <Logo :fill="'#ffffff'" :height="'33px'" :width="'33px'" class="mb-0 me-2 d-flex align-items-center"></Logo> <span id="logotext"> Newsletter</span>
      </a>
      

      <div class="collapse navbar-collapse" id="navbarSupportedContent" >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-show="(user?.loggedIn && $route.meta.requiresAuth && isUser())">
          <li :class="`nav-item dropdown ${$route.path.includes('/mailing') ? 'active' : ''}`">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" ref="dropDownMailings" data-bs-toggle="dropdown" @click="dropdownMailings.toggle()" aria-expanded="false">
              Mailings
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link :class="`dropdown-item ${$route.path === '/mailing/create' ? 'active' : ''}`" :to="`/mailing/create`">Mailing Erstellen</router-link ></li>
              <li><router-link :class="`dropdown-item ${$route.path === '/mailing/list' ? 'active' : ''}`" :to="`/mailing/list`">Mailings in Arbeit</router-link ></li>
            </ul>
          </li>

          <li :class="`nav-item just-link  ${!$route.path.includes('mailinfotest') && $route.path.includes('mailinfo') ? 'active' : ''}`">
            <router-link  :class="`nav-link`" :to="`/mailinfo`">Versendet</router-link >
          </li>

          <li :class="`nav-item dropdown ${$route.path.includes('distributor') ? 'active' : ''}`">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" ref="dropDownVerteiler" data-bs-toggle="dropdown" @click="dropdownVerteiler.toggle()" aria-expanded="false">
              Verteiler
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!-- <li><router-link  :class="`dropdown-item ${$route.path === '/distributor/import' ? 'active' : ''}`" :to="`/distributor/import`">Import</router-link ></li>
              <li><router-link  :class="`dropdown-item ${$route.path === '/distributor/list' ? 'active' : ''}`" :to="`/distributor/list`">Verteiler</router-link ></li>
              <li><router-link  :class="`dropdown-item ${$route.path === '/distributor/persons' ? 'active' : ''}`" :to="`/distributor/persons`">Abonenten</router-link ></li> -->
              <li><router-link  :class="`dropdown-item ${$route.path === '/distributor/listtest' ? 'active' : ''}`" :to="`/distributor/listtest`">Verteiler für Testversand</router-link ></li>
              <!-- <li><router-link  :class="`dropdown-item ${$route.path === '/distributor/createtest' ? 'active' : ''}`" :to="`/distributor/createtest`">Verteiler für Testversand erstellen</router-link ></li> -->
              <!-- <li><router-link  :class="`dropdown-item ${$route.path === '/distributor/createcategory' ? 'active' : ''}`" :to="`/distributor/createcategory`">Kategorie erstellen</router-link ></li> -->
              <li><router-link  :class="`dropdown-item ${$route.path === '/distributor/editcategorys' ? 'active' : ''}`" :to="`/distributor/editcategorys`">Kategorien übersetzen</router-link ></li>
            </ul>
          </li>

          <li :class="`nav-item just-link  ${$route.path.includes('report') || $route.path.includes('report') ? 'active' : ''}`">
            <router-link  :class="`nav-link`" :to="`/report`">Report</router-link >
          </li>

          <li :class="`nav-item just-link  ${$route.path.includes('dokumentation') || $route.path.includes('dokumentation') ? 'active' : ''}`">
            <router-link  :class="`nav-link`" :to="`/dokumentation`">Dokumentation</router-link >
          </li>

          <li :class="`nav-item just-link  ${$route.path.includes('settings') || $route.path.includes('settings') ? 'active' : ''}`">
            <router-link  :class="`nav-link`" :to="`/settings`">Settings</router-link >
          </li>

        </ul>
      </div>


      <div class="text-right" style="min-width: 195px; text-align: right;" v-if="user?.loggedIn && $route.meta.requiresAuth">
        <a class="logout" href="/" @click="logOut()">
          <font-awesome-icon :icon="['fas', 'sign-out']" />
          <!-- Logout -->
        </a>
      </div>
      <div class="text-right" v-if="!$route.meta.requiresAuth">
        <Lang />
      </div>
    </div>
  </nav>

</template>


<script>
import Logo from '../assets/Logo'
import { mapGetters } from "vuex";
import { getAuth, signOut } from "firebase/auth"
import Lang from "../components/smallComponents/Lang"
import { Dropdown } from "@comlinedev/bootstrap5"

export default {
  name: "Navigation",
  components: {
    Logo,
    Lang
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  watch: {

  },
  data(){
    return{
      settings: 0,
      dropdownMailings: null,
      // dropdownAuswertung: null,
      dropdownVerteiler: null,
    }
  },
  methods: {
    logOut(){
      // console.log('logginOut')
      signOut(getAuth()).then(() => {
        // Sign-out successful.
        // console.log('logged Out')
        this.$router.push('login')
      }).catch((error) => {
        // An error happened.
        // console.log('not working')
        console.error(error)
      });
    },
    async isUser(){
      let curentUser = await getAuth().currentUser
      let user = curentUser?.claims?.user
      return user
    }
  },
  mounted(){
    this.dropdownMailings = new Dropdown(this.$refs.dropDownMailings)
    // this.dropdownAuswertung = new Dropdown(this.$refs.dropDownAuswertung)
    this.dropdownVerteiler = new Dropdown(this.$refs.dropDownVerteiler)
  }
};
</script>

<style scoped>
  .dropdown-menu {
    z-index: 2000;
  }
  nav .navbar-brand,
  .navbar-collapse>ul>li>a,
  .logout {
    color: white !important; 
    text-decoration: none;
  }
  li.dropdown.active>a,
  li.just-link.active>a {
    color: #4089c9 !important;
    background-color: #ffffff;
    border: 1px solid rgb(255, 255, 255);
    border-radius: .25rem;
  }
  li.dropdown, 
  li.just-link {
    padding: 0px 3px;
  }
  .clickable {
    cursor: pointer;
  }
  .clickable:hover {
    color: darkgray;
  }
</style>