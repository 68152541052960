<template>
<div class="d-flex justify-content-center mb-2 mt-2">
  <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li :class="`breadcrumb-item ${$route.path === `/mailing/general/${$route.params.id}` ? 'active' : ''}`"><router-link :to="`/mailing/general/${$route.params.id}`"><code>Übersicht</code></router-link ></li>
      <li :class="`breadcrumb-item ${$route.path === `/mailing/recipients/${$route.params.id}` ? 'active' : ''}`"><router-link :to="`/mailing/recipients/${$route.params.id}`"><code>Empfänger</code></router-link ></li>
      <li :class="`breadcrumb-item ${$route.path === `/mailing/preview/${$route.params.id}` ? 'active' : ''}`"><router-link :to="`/mailing/preview/${$route.params.id}`"><code>Preview</code></router-link ></li>
      <li :class="`breadcrumb-item ${$route.path === `/mailing/priceloader/${$route.params.id}` ? 'active' : ''}`"><router-link :to="`/mailing/priceloader/${$route.params.id}`"><code>Preise</code></router-link ></li>
      <li :class="`breadcrumb-item ${$route.path === `/mailing/previewfilled/${$route.params.id}` ? 'active' : ''}`"><router-link :to="`/mailing/previewfilled/${$route.params.id}`"><code>Preview gefüllt</code></router-link ></li>
      <li :class="`breadcrumb-item ${$route.path === `/mailing/testdelivery/${$route.params.id}` ? 'active' : ''}`"><router-link :to="`/mailing/testdelivery/${$route.params.id}`"><code>Testversand</code></router-link ></li>
      <li :class="`breadcrumb-item ${$route.path === `/mailing/delivery/${$route.params.id}` ? 'active' : ''}`"><router-link :to="`/mailing/delivery/${$route.params.id}`"><code>Versand</code></router-link ></li>
    </ol>
  </nav>
</div>
</template>

<script>

export default {
  name: 'card',

  data(){
    return{

    }
  },
  mounted(){

  },
}
</script>

<style scoped>
  code {
    border: 1px #ced4da solid;
    border-radius: .25rem;
    padding: 5px;
  }
  .active code {
    color: #a9a9a9 !important;
  }
  a {
    text-decoration: none !important;
    line-height: 10px !important;
  }
</style>
