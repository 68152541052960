<template>
  <div class="card" v-if="list1.length > 0 || list2.length > 0">
    <div class="card-body">
      <h5 class="card-title">Neue Anmeldung</h5>
      <p>in den letzten 10 Tagen</p>
      <div class="row" style="text-align: left;">
        <div class="col">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between" v-for="(each, index) in list1" :key="index">
              <!-- <router-link  class="dropdown-item" :to="`/distributor/persons/edit/${each.id}`">{{each?.firstname}} {{each?.lastname}} {{each.email}}</router-link >s -->
              <span>{{each?.firstname}} {{each?.lastname}} {{each.email}}</span>
              <span>{{convertDate(each?.confirmationDate)}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirestore, query, collection, where, onSnapshot } from "firebase/firestore"; 

export default {
  name: 'UserConformation',
  components: {

  },
  data(){
    return {
      list1:[],
      list2: [],
      stopLoadSubscribers1: null,
    }
  },
  methods: {
    loadSubscribers(){
      const tenDaysAgo = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000)  
      const q1 = query(collection(getFirestore(), "subscribers"), where("confirmationDate", ">=", tenDaysAgo));

      this.stopLoadSubscribers1 = onSnapshot(q1, (querySnapshot) => {
        this.list1 = []
        querySnapshot.forEach((doc) => {
          console.log(doc.data())
          let data = doc.data()
          data.id = doc.id
          this.list1.push(data)
        });
      });
    },
    convertDate(date){
      return `${date.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })} ${date.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})} Uhr`
    },
  },
  mounted(){
    this.loadSubscribers()
  },
  unmounted(){
    this.stopLoadSubscribers1()
  }
}
</script>

<style scoped>
li::marker  {
	color: transparent; 
	font-size: 1em;
}
</style>