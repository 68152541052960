<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col text-center p-4">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="7x" class="m-4"/>
          <h5>404</h5>
          <h5>Seite nicht gefunden.</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: '404',
  components:{

  },
  computed: {

  },
}
</script>

<style scoped>

</style>