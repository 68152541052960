<template>
  <div class="text-left">
    <h5 class="mb-5 mt-5 text-center">Auswertung</h5>
    <div class="container mb-5">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <nav aria-label="Page navigation example">
              <ul class="pagination" style="cursor: pointer;">
                <li :class="`page-item ${show === 'Link' && 'active'}`"><a class="page-link" @click="show = 'Link'">Link Klick</a></li>
                <li :class="`page-item ${show === 'Opening' && 'active'}`"><a class="page-link" :active="show === 'Opening'" @click="show = 'Opening'">Öffnung</a></li>
                <li :class="`page-item ${show === 'OpeningTime' && 'active'}`"><a class="page-link" :active="show === 'OpeningTime'" @click="show = 'OpeningTime'">Öffnungen/Zeit</a></li>
                <!-- <li :class="`page-item ${show === 'Browser' && 'active'}`"><a class="page-link" :active="show === 'Browser'" @click="show = 'Browser'">Client/OS</a></li>           -->
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'Link'">
        <div class="col">
          <div class="custombox">
            <HorizontalChart v-if="!working" :dataSets="linkChartData" :links="links"/>
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'Opening'">
        <div class="col">
          <div class="custombox">
           <CircleChart style="margin: 0px 20%;" v-if="!working" :dataSets="viewChartData.dataSets" :labels="viewChartData.labels" />
          </div>
        </div>
      </div>
      <div class="row" v-show="show === 'OpeningTime'">
        <div class="col">
          <div class="custombox">
            <LineTimeChart v-if="!working" :dataSets="lineChertTimedData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirestore, getDocs, query, collection, doc} from "firebase/firestore"; 
import HorizontalChart from './HorizontalChart2.vue'
import LineTimeChart from './LineTimeChart.vue'
import CircleChart from './CircleChart.vue'
import extractDomain from "extract-domain";
import moment from "moment-timezone"

export default {
  name: 'Evaluation',
  components: {
    HorizontalChart,
    CircleChart,
    LineTimeChart,
  },
  data(){
    return{
      working: true,
      show: 'Link',
      linkChartData: null,
      links: [],
      lineChertTimedData: null,
      viewChartData: {
        dataSets: [
          { 
            label: 'Geöffnet',
            backgroundColor: ['#4089c9', '#e9ecef'],
            data: []
          }
        ],
        labels:[]
      },
      search: ''
    }
  },
  methods:{
    async getViews(){
      const docRef = await doc(getFirestore(), "mailing", this.$route.params.id);
      let views = 0
      const viewRef = await query(collection(docRef, "views"));
      const viewSnapshot = await getDocs(viewRef);
      views = viewSnapshot.size
      this.viewChartData.dataSets[0].data[0] = views
      
      const sendsRef = await query(collection(docRef, "webview"));
      const sendsSnap = await getDocs(sendsRef);
      const sends = sendsSnap.size
      this.viewChartData.dataSets[0].data[1] = (sends - views)

      const viewsInPercent = (views / sends) * 100
      const noViewsInPercent = ((sends - views) / sends)*100
      this.viewChartData.labels[0] = `Geöffnet ${viewsInPercent.toFixed(2)}%`
      this.viewChartData.labels[1] = `Nicht geöffnet ${noViewsInPercent.toFixed(2)}%`
    },
    async getClicks(){
      const dataset = {
        data1: [],
        data2: [],
        names: []
      }

      const docRef = await doc(getFirestore(), "mailing", this.$route.params.id);
      const TrackingRef = await query(collection(docRef, "tracking"));
      const querySnapshot = await getDocs(TrackingRef);
      let snapshotData = []
      querySnapshot.forEach((doc) => {
        snapshotData.push(doc.data())
      })

      snapshotData.sort(function (a, b) {
              if(a.subscribers.length >= b.subscribers.length) {
                return -1;
              } else {
                return 1;
              }
            })

      snapshotData.forEach((data)=>{
        
          let name  
          if(data.name != 'null' && data.name != null && data.name != 'undefined' && data.name ){
             name = data.name
          }else {
            name = extractDomain(data.link)
          }
          dataset.names.push(name)

          dataset.data1.push(data.subscribers.length)
          
          const ids = [];
          const unique = data.subscribers.filter(element => {
            const isDuplicate = ids.includes(element.subscriberId);
            if (!isDuplicate) {
              
              ids.push(element.subscriberId);
              return true;
            }
          });
          dataset.data2.push(unique.length)

          this.links.push(data.link)
        })
      this.linkChartData = dataset
    },
    async getClicksTimed(){

      const docRef = await doc(getFirestore(), "mailing", this.$route.params.id);
      const TrackingRef = await query(collection(docRef, "views"));
      const querySnapshot = await getDocs(TrackingRef);

      let snapshotData = []
      querySnapshot.forEach((doc) => {
        snapshotData.push(doc.data())
      })
      // order by date (combine days)
      snapshotData.sort(function (a, b) {
        if(a.date.toDate().getTime() <= b.date.toDate().getTime()) {
          return -1;
        } else {
          return 1;
        }
      })

      let countedData = snapshotData.reduce(function (result, line) {
        var day = moment(line.date.toDate()).format("YYYY-MM-DD");
        if (!result[moment(day).format('x')]) {
            result[moment(day).format('x')] = 0;
        }
        result[moment(day).format('x')]++;
        return result;
      }, {});
      
      const finalData = []
      Object.entries(countedData).forEach((data)=>{
        finalData.push([Number(data[0]), data[1]])
      })
      this.lineChertTimedData = finalData
    }
  },
  async mounted(){
    await this.getClicks()
    await this.getViews()
    await this.getClicksTimed()
    this.working = false
  }
}
</script>

<style scoped>
  .custombox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
  }
</style>