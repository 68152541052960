<template>
  <BreadCrumb/>
    <OverView/>
  <div class="container text-center mt-3 mb-5">
    <h3>Preview</h3>
    <Loading v-if="working" />
    <div v-else>
      <div class="mb-3">
        <select class="form-select" v-model="selected" @change="reload()">
          <option v-for="each in subscribers" :key="each" :value="each">{{subscriberNames[each]}}</option>
        </select>
      </div>

      <div class="d-flex justify-content-center">
        <nav aria-label="Page navigation example">
          <ul class="pagination" style="cursor: pointer;">
            <li :class="`p-0 page-item ${show === 'html' && 'active'}`"><a class="page-link" @click="show = 'html'">HTML</a></li>
            <li :class="`p-0 page-item ${show === 'text' && 'active'}`"><a class="page-link" :active="show === 'text'" @click="show = 'text'">TEXT</a></li>
          </ul>
        </nav>
      </div>

      <div class="scrollBox" v-show="(show === 'html')">
        <iframe 
          class="iframe"
          title="Mailing"
          :src="iframeSrc">
        </iframe>
      </div>

      <div v-show="(show === 'text')">
        <div class="row mb-5">
          <div class="col text-left">
            <pre>{{text}}</pre>
          </div>
        </div>
      </div>

    </div>
    <div class="row mt-3">
      <div class="col text-left d-flex justify-content-between mt-5 mb-5">
        <button class="btn btn-secondary" @click="this.$router.push({ name: 'Testversand', params: { id: this.$route.params.id } })">Testversand</button>
        <button class="btn btn-secondary" @click="this.$router.push({ name: 'Versand', params: { id: this.$route.params.id } })">Versand</button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../Loading.vue'
import BreadCrumb from '../smallComponents/MailBreadCrumb.vue'
import OverView from '../smallComponents/MailOverview.vue'
import { mapGetters } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, getDoc, doc, query, where, limit, collection, getDocs, } from "firebase/firestore";

export default {
  name: 'Preview',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  components: {
    Loading,
    BreadCrumb,
    OverView
  },
  data(){
    return {
      working: true,
      text: 'loading',
      show: 'html',
      iframeSrc: '',
      subscribers: null,
      selected: 0,
      subscriberNames: {},
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings",
    })
  },
  methods:{
    async loadData(){
      const subscribers = await this.getsubscribers(this.$route.params.id)
      // console.log(subscribers)
      this.subscribers = subscribers
      this.selected = subscribers[0]
      subscribers.forEach(d=>{this.subscriberNames[d] = 'Pleas wait...'; this.getSubscriberName(d)})
      this.reload()
    },
    async getsubscribers(mailingId){
      const mailingSnap = await getDoc(doc(getFirestore(), "mailing", mailingId ));
      if (mailingSnap.exists()) {
        const mailingData = mailingSnap.data()

        if(Array.isArray(mailingData.recipientListPersonIds)){
          return mailingData.recipientListPersonIds
        } else {
          const distibutorSnap = await getDoc(doc(getFirestore(), "distributor", mailingData.distributor ));
          if (distibutorSnap.exists()) {
            let distibutor = distibutorSnap.data()
            return distibutor.selectedUsers
          } else {
          console.error('Fehler beim abrufen der VerteilerDaten.')
          }
        }
       
      } else {
        console.error('Fehler beim abrufen der Mailingdaten.')
      }
    },
    async getSubscriberName(personId){
      const subscriberRef = await query(collection(getFirestore(), "subscribers"), where('personId', '==', personId), limit(1));
      const querySnapshot = await getDocs(subscriberRef);
      if (querySnapshot.empty) {
        this.subscriberNames[personId] = 'Subscriber wurden nicht gefunden.'
        let toRomove = this.subscribers.findIndex((element) => element === personId)
        this.subscribers.splice(toRomove, 1)
        console.error('Subscriber wurden nicht gefunden.')
      } else {
        querySnapshot.forEach((doc) => {

          const subscriber = doc.data()
          if(subscriber.email == ""){console.log(subscriber)}
          this.subscriberNames[personId] =  ` ${subscriber.email}`
        })
      }
    
    },
    async reload(){
      // this.working = true
      // this.text = 'Loading..'
      this.iframeSrc = `${window.location.origin}/mailing/showmailing/${this.$route.params.id}/${this.selected}`

      await this.getTextWithData({mailingId: this.$route.params.id, subscriber: this.selected})
      .then(res=>{
        // console.log(res.data)
        this.text = res.data
      })
      .catch(e=>{
        console.error(e)
      })
      this.working = false
    },
  },
  mounted(){
    this.loadData()
    this.getTextWithData = httpsCallable(getFunctions(this.firebase, this.region), 'getTextWithData');
  },
}
</script>

<style scoped>
  .scrollBox {
    border: 1px #f2f2f2 solid;
    max-height: 900px; 
    overflow-y: scroll;
  }
    .iframe {
    width: 100%;
    height: 100%;
    min-height: 900px;
  }
  pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word; 
    text-align: left; 
    max-width: 100%; 
    overflow-y: hidden;
  }
</style>