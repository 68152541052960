<template>
  <BreadCrumb/>
    <OverView/>
  <div class="container text-center mt-3 mb-5">
    <h3 class="mb-5">Preview</h3>
    <Loading v-if="working" />
    <template v-else>
      <div class="d-flex justify-content-center">
        <nav aria-label="Page navigation example">
          <ul class="pagination" style="cursor: pointer;">
            <li :class="`page-item ${show === 'html' && 'active'}`"><a class="page-link" @click="show = 'html'">HTML</a></li>
            <li :class="`page-item ${show === 'text' && 'active'}`"><a class="page-link" :active="show === 'text'" @click="show = 'text'">TEXT</a></li>
            <li :class="`page-item ${show === 'links' && 'active'}`"><a class="page-link" :active="show === 'links'" @click="show = 'links'">Links</a></li>
          </ul>
        </nav>
      </div>
      <div class="scrollBox" v-show="show === 'html'">
        <iframe 
          class="iframe"
          title="Mailing"
          :src="iframeSrc">
        </iframe>
      </div>
      <div v-show="show === 'text'">
        <div class="row mb-5">
          <div class="col text-left">
            <pre>{{text}}</pre>
          </div>
        </div>
      </div>
      <div v-show="show === 'links'">
        <div class="row mb-5">
          <Links/>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col text-left d-flex justify-content-between mt-5 mb-5">
          <button class="btn btn-secondary" @click="this.$router.push({ name: 'Preis vorschau', params: { id: this.$route.params.id } })">Weiter zum Preisimport</button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import Loading from '../Loading.vue'
import BreadCrumb from '../smallComponents/MailBreadCrumb.vue'
import OverView from '../smallComponents/MailOverview.vue'
import Links from './Linklist.vue'
import { mapGetters } from "vuex";
import { getFirestore, doc, getDoc, addDoc, collection } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  name: 'Preview',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  components:{
    Loading,
    BreadCrumb,
    OverView,
    Links
  },
  data(){
    return {
      working: true,
      mailing: false,
      iframeSrc: '',
      text: 'Loading...',
      show: 'html'
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings",
    })
  },
  methods:{
    async mailLog(action, id){
      let mailId = this.$route.params.id ? this.$route.params.id : id;
      const ref = await collection(getFirestore(), "mailing", mailId, "log");
      await addDoc(ref, {action: action, time: new Date()})
    },
    async loadData(){
      const docSnap = await getDoc(doc(getFirestore(), "mailing", this.$route.params.id ));
        this.mailLog('Mailing wurde angesehen.')
      if (docSnap.exists()) {
        this.mailing = docSnap.data()
        return 'ok'
      } else {
        // console.log("No such document!");
        return 'error'
      }
    },
    async readTextFile(folder) {
      const getTXT = httpsCallable(getFunctions(this.firebase, this.region), 'getTXTfromServer');
      getTXT(folder)
      .then(res=>{
        this.text = res.data
      })
      .catch(err =>{
        console.error(err)
      })
    },
  },
  mounted(){
    this.loadData()
    .then(async ()=>{
      this.iframeSrc = this.settings.webserver + this.mailing.mailing
      await this.readTextFile(this.mailing.mailing)
      this.working = false
    })
  },
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
  .scrollBox {
    border: 1px #f2f2f2 solid;
    max-height: 900px; 
    overflow-y: scroll;
  }
  .iframe {
    width: 100%;
    height: 100%;
    min-height: 900px;
  }
  .pre-formatted {
    white-space: pre;
    overflow-wrap: break-word;
  }
  pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word; 
    text-align: left; 
    max-width: 100%; 
    overflow-y: hidden;
  }
</style>