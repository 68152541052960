<template>
  <div class="dropdown">
    <button :class="button ? 'btn btn-primary ps-3 pe-3' :'btn btn-link btn-sm p-0'" type="button" :ref="`dropdownMenu${id}`" data-bs-toggle="dropdown" @click="dropDown.toggle()" aria-expanded="false">
      <font-awesome-icon :icon="['fas', 'ellipsis-h']" :color="`${button ? 'white' : 'grey'}`" />
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <li><a  class="dropdown-item" @click="showDeleteModal(id)" href="#"><font-awesome-icon :icon="['fas', 'trash']" color="grey;" /> Löschen</a ></li>
      <li><a  class="dropdown-item" @click="dublicate(id)" href="#"><font-awesome-icon :icon="['fas', 'clone']" color="grey;" /> Dublizieren</a ></li>
      <hr class="m-2">
      <li><router-link  class="dropdown-item" :to="`/mailing/general/${id}`">Hauptdaten</router-link ></li>
      <li><router-link  class="dropdown-item" :to="`/mailing/recipients/${id}`">Empfänger</router-link ></li>
      <li><router-link  class="dropdown-item" :to="`/mailing/preview/${id}`">Preview</router-link ></li>
      <li><router-link  class="dropdown-item" :to="`/mailing/priceloader/${id}`">Preisliste</router-link ></li>
      <li><router-link  class="dropdown-item" :to="`/mailing/previewfilled/${id}`">Preview mit Preisen</router-link ></li>
      <li><router-link  class="dropdown-item" :to="`/mailing/testdelivery/${id}`">Testversand</router-link ></li>
      <li><router-link  class="dropdown-item" :to="`/mailing/delivery/${id}`">Versand</router-link ></li>
    </ul>
  </div>

  <div class="modal" tabindex="-1" ref="modalDelete">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="toDelete">{{list.find(element => element.id === toDelete).data.name}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="toDelete">
          <p>Soll das Mailing "<b>{{list.find(element => element.id === toDelete).data.name}}</b>" gelöscht werden?</p>
          <div class="alert alert-warning">Das Mailing kann nach dem Löschen nicht wiederhergestellt werden.</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="color: white;" @click="deleteMailing()">Löschen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dropdown } from "@comlinedev/bootstrap5"
import { getFirestore, getDoc, doc, addDoc, collection, query, getDocs, deleteDoc} from "firebase/firestore"; 
import { Modal } from "@comlinedev/bootstrap5"

export default {
  name: 'dropdown',
  props:{
    id: String,
    button: Boolean
  },
  data(){
    return {
      dropDown: null,
      toDelete: null,
      deleteModal: null,
      list: null
    }
  },
  methods:{
    async dublicate(id){
      await getDoc(doc(getFirestore(), "mailing", id ))
      .then(async snap =>{
        const oldData = snap.data()
        let newData = {}
        newData.name = 'Kopie - ' + oldData.name
        newData.timestamp = new Date()
        newData.priceImport = false
        newData.mailing = oldData.mailing
        newData.sender = oldData.sender

        await addDoc(collection(getFirestore(), "mailing"), newData)
        .then((a)=>{
          this.$router.push(`/mailing/general/${a.id}`)
        })

      })
    },
    showDeleteModal(id){
      this.toDelete = id
      // console.log('Modal öffnen...')
      this.deleteModal.show()
    },
    deleteMailing(){
      deleteDoc(doc(getFirestore(), "mailing", this.toDelete))
      .then(()=>{
        this.$router.go(this.$router.currentRoute)
        this.success
      })
      .catch(err=>{
        this.err = err
      })
    },
    async loadData(){
      const mailingsRef = query(collection(getFirestore(), "mailing"));
      const querySnapshot = await getDocs(mailingsRef);
      this.list = []
      querySnapshot.forEach((doc) => {
        this.list.push({ id: doc.id, data: doc.data()})
      })
      return 'ok'
    },
  },
  mounted(){
    this.dropDown = new Dropdown(this.$refs[`dropdownMenu${this.id}`])
    this.deleteModal = new Modal(this.$refs.modalDelete)
    this.loadData()
  }
}
</script>

<style>

</style>
