<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <h1 class="mb-5 mt-5" >Dokumentation</h1>
        </div>
      </div>
      <div class="row">
        <div class="col text-left p-0">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a :class="`nav-link ${page === 'var' && 'active'}`" href="#" @click="page = 'var'">Variablen</a>
            </li>
            <li class="nav-item">
              <a :class="`nav-link ${page === 'del' && 'active'}`" href="#" @click="page = 'del'">Versand</a>
            </li>
            <li class="nav-item">
              <a :class="`nav-link ${page === 'dist' && 'active'}`" href="#" @click="page = 'dist'">Verteiler</a>
            </li>
            <li class="nav-item">
              <a :class="`nav-link ${page === 'temp' && 'active'}`" href="#" @click="page = 'temp'">Template</a>
            </li>
            <li class="nav-item">
              <a :class="`nav-link ${page === 'gen' && 'active'}`" href="#" @click="page = 'gen'">Grundlagen</a>
            </li>
          </ul>
        </div>
        <div class="box mb-5">
          <Variabels v-if="page === 'var'"/>
          <Delivery v-if="page === 'del'"/>
          <Distributor v-if="page === 'dist'"/>
          <Template v-if="page === 'temp'"/>
          <General v-if="page === 'gen'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Variabels from '../components/doku/mailingVariables.vue'
import Delivery from '../components/doku/delivery.vue'
import Distributor from '../components/doku/distributor.vue'
import Template from '../components/doku/template.vue'
import General from '../components/doku/general.vue'

export default {
  name: 'Home',
 data(){
    return{
      page: 'var'
    }
  },
  components: {
    Variabels,
    Delivery,
    Distributor,
    Template,
    General
  },
}
</script>

<style scoped>
  .box {
  border: 1px solid #dee2e6;
  border-top: none; 
  border-radius: 0 0 .25rem .25rem;
  padding: 15px;
}
</style>