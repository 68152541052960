<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Mailings</h5>
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between" v-for="each in mailings" :key="each.id">
          <div @click="goTo(each.id, each.data.status)" :class="each.data.status != 'initialized'&& 'clickable'" class="w-100 me-5">
            {{each.data.name}}<br>
            <small>erstellt: {{each.data.timestamp.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })}} {{each.data.timestamp.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})}} Uhr </small>
          </div>
          <div v-if="!each.data.status">
            <DropDown :id="each.id"/>
          </div>
          <div v-else class="d-flex align-items-center"> 
            <span style="color: orange;"><font-awesome-icon v-if="each.data.status == 'initialized' " :icon="['fas', 'hourglass-half']" size="1x"/></span>
            <span style="color: green;"><font-awesome-icon v-if="each.data.status == 'done'" :icon="['fas', 'check-double']" size="1x"/></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { query, orderBy, limit, collection, getFirestore, getDocs } from "firebase/firestore";  
import DropDown from "../smallComponents/MailDropDownMenu.vue"

export default {
  name: 'MailingsShort',
  components: {
    DropDown
  },
  data(){
    return {
      mailings: []
    }
  },
  async mounted(){
    const mailingsRef = await collection(getFirestore(), "mailing");
    getDocs(query(mailingsRef, orderBy("timestamp", "desc"), limit(10)))
    .then(res=>{
      res.forEach(each=>{
        this.mailings.push({id: each.id, data: each.data()})
      })
    })
  },
  methods:{
    goTo(id, status){
      if(status == 'done'){
        this.$router.push({ name: 'MailingInfo', params: { id: id } })
      } else if(status == 'initialized'){
        return
      } else {
        this.$router.push({ name: 'Infoseite', params: { id: id } })
      }
    }
  }
}
</script>

<style scoped>
small {
  font-size: 10px;
  line-height: 11px;
}
li {
  text-align: left;
  line-height: 15px;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: darkgray;
}
</style>