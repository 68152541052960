<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col text-center p-4">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="7x" class="m-4"/>
          <h5>Es ist Ihnen nicht erlaubt dieses Tool zu Nutzen. Bitte Wenden Sie sich an comlinedev@comline-shop.de.</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotAllowed',
  components:{

  },
  computed: {

  },
}
</script>