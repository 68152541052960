<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Status</h5>
      <div class="row">
        <div class="col">
          <p>Preisimport: <Check :check="killswitch?.priceloader"/></p>
        </div>
        <div class="col">
          <p>Mailversand: <Check :check="killswitch?.sendMail"/></p>
        </div>
      </div>
      <div class="row" style="text-align: left;">
        <div class="col">
          <template v-if="mailingsSending.length > 0">
            <p>Mailings im Versand:</p>
            <ul>
              <li v-for="(each, index) in mailingsSending" :key="index"><b>{{each.name}}</b>
              <span v-if="each.status === 'stop'" class="badge bg-danger m-2">Angehalten</span>      
              <div class="progress">
                <div class="progress-bar" role="progressbar" :style="`width: ${each.percent}%`" aria-valuemax="100">{{each.percent}}%</div>
              </div>
            </li>
            </ul>
          </template>

          <template v-if="mailingsToSend.length > 0">
            <p>Terminierte Mailings:</p>
            <ul>
              <li v-for="(each, index) in mailingsToSend" :key="index"><b>{{each.name}}</b> <span v-if="each.data.status !== 'stop'">wird {{convertDate(each.data.date)}} Versendet.</span> <span v-else class="badge bg-danger">Angehalten</span></li>
            </ul>
          </template>

          <template v-if="priceimport?.priceImport">
            <p>Preise von "{{priceimport.name}}" werden importiert</p>
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="`width: ${priceimport.priceImport.count}%`" aria-valuemax="100">{{priceimport.priceImport.count}}%</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirestore, getDoc, doc, query, collection, where, onSnapshot } from "firebase/firestore"; 
import Check from '../smallComponents/Check.vue'

export default {
  name: 'States',
  components: {
    Check
  },
  data(){
    return {
      killswitch: null,
      priceimport: null,
      stopMailingsToSend: null,
      stopMailingsSending: null,
      stopPriceload: null,
      mailingsToSend: [],
      mailingsSending:[]
    }
  },
  methods: {
    loadPriceload(){
      const q = query(collection(getFirestore(), "mailing"), where("priceImport.state", "!=", "done"));
      this.stopPriceload = onSnapshot(q, (querySnapshot) => {
        this.priceimport = null
        querySnapshot.forEach((doc) => {
          this.priceimport = doc.data()
        });
      });
    },
    loadMailingsToSend(){
      const q = query(collection(getFirestore(), "sendMailing"), where("isTimed", "==", true), where('initialized', '==', true));
      this.stopMailingsToSend = onSnapshot(q, (querySnapshot) => {
        this.mailingsToSend = []
        querySnapshot.forEach(async (doc) => {
          this.mailingsToSend.push({name: await this.getMailingName(doc.id), data: doc.data()})
        });
      });
    },
    loadMailingsSending(){
      const q = query(collection(getFirestore(), "sendMailing"), where('initialized', '==', true), where("isTimed", "!=", true));
      this.stopMailingsSending = onSnapshot(q, (querySnapshot) => {
        this.mailingsSending = []
        querySnapshot.forEach(async (doc) => {
          // console.log('MAILS', doc.data())
          const fullLength = doc.data().recipientListPersonIdsLength
          const percent = Math.round(((fullLength - doc.data().recipientListPersonIds.length) / fullLength) * 100)
          const status = doc.data()?.status
          this.mailingsSending.push({name: doc.data().subject, percent: percent, status: status})
        });
          // console.log('mailSending',this.mailingsSending)
      });
    },
    async getMailingName(id){
      const settingsSnap = await getDoc(doc(getFirestore(), "mailing", id ));
      if (settingsSnap.exists()) {
        return settingsSnap.data().name
      }
      return
    },
    convertDate(date){
      return `${date.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })} ${date.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})} Uhr`
    },
    async getDistributorLength(id){
      const mailingSnap = await getDoc(doc(getFirestore(), "mailing", id ));
      if (mailingSnap.exists()) {
        const distriSnap = await getDoc(doc(getFirestore(), "distributor", mailingSnap.data().distributor ));
        if (distriSnap.exists()) {
          return distriSnap.data().selectedUser.length
        }
      }
      return 0
    }
  },
  async mounted(){
    const settingsSnap = await getDoc(doc(getFirestore(), "settings", "killswitch" ));
    if (settingsSnap.exists()) {
      this.killswitch = settingsSnap.data()
    }
    this.loadPriceload()
    this.loadMailingsToSend()
    this.loadMailingsSending()
  },
  unmounted(){
    this.stopPriceload()
    this.stopMailingsToSend()
    this.stopMailingsSending()
  }
}
</script>

<style scoped>
li::marker  {
	color: transparent; 
	font-size: 1em;
}
</style>