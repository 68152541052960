<template>
  <BreadCrumb/>
    <OverView/>
  <div class="container mt-3 mb-5">
    <h3 class="mb-3 text-center">Testversand</h3>
    <Loading v-if="working"/>
    <div v-else-if="!done" class="text-left">

        <div class=" mt-5">
          <label for="prefix" class="form-label">Titel Präfix</label><Info class="ms-2" content="Der Präfix wird vor den Betreff gesetzt bei einem Testversand."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
          <input type="text" class="form-control" placeholder="Testmail -" v-model="prefix" aria-label="prefix">
        </div>

        <label class="form-label mt-5" for="distributor" >Verteiler</label><Info class="ms-2" content="Hier kannst du einen Testverteiler wählen für den testversand."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
        <select class="form-select" v-model="selectedTestDistibutor" aria-label="distributor">
          <option selected disabled :value="null">Wähle einen Verteiler (optional)</option>
          <option v-for="each in Object.entries(testDistributor)" :key="each[0]" :value="each">{{each[1].name}}</option>
        </select>
        <label class="form-label mt-3">Weitere Empfänger</label><Info class="ms-2" content="Es können weitere Empfänger hinzugefügt werden. Eine Kundennummer ist immer erforderlich."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
        <div class="d-flex align-items-center" v-for="address, index in addedMailAddresses" :key="index">
          <input type="email" class="form-control mt-1" placeholder="E-Mail-Adresse" v-model="addedMailAddresses[index]['email']" aria-label="Mail">
          <input type="text" class="form-control mt-1 ms-2" placeholder="Kundennummer" v-model="addedMailAddresses[index]['customernumber']" aria-label="Kundennummer">
          <button class="btn" @click="addedMailAddresses.length > 1 && addedMailAddresses.splice(index, 1);"><font-awesome-icon :icon="['fas', 'times']" /></button>
        </div>
        <button class="btn" @click="addedMailAddresses.push({email: '', customernumber: '100001'})"><font-awesome-icon :icon="['fas', 'plus']" /> Weiterer Empfänger</button>

        <div v-if="errormessage" class="alert alert-danger mt-3">{{errormessage}}</div>

        <div class="mt-5 text-center">
          <button class="btn btn-primary" @click="sendMail()"><font-awesome-icon :icon="['fas', 'paper-plane']" /> Absenden</button>
        </div>
      </div>
    <div  v-else class="alert alert-success">OK</div>
  </div>
</template>

<script>
import Loading from '../Loading.vue'
import BreadCrumb from '../smallComponents/MailBreadCrumb.vue'
import OverView from '../smallComponents/MailOverview.vue'
import { mapGetters } from "vuex";
import { getFirestore, doc, setDoc, collection, query, onSnapshot, addDoc } from "firebase/firestore";
import Info from '../smallComponents/Info.vue'

export default {
  name: 'TestDelivery',
  components:{
    Loading,
    BreadCrumb,
    OverView,
    Info
  },
  data(){
    return {
      working: true,
      testDistributor: null,
      prefix: null,
      addedMailAddresses: [{email: '', customernumber: '100001'}],
      sender: {email: "marketing@comline-shop.de", name: "ComLine GmbH"},
      selectedTestDistibutor: null,
      done: false,
      senders: [],
      selected: null,
      errormessage: null
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings",
    })
  },
  methods:{
    async mailLog(action, id){
      let mailId = this.$route.params.id ? this.$route.params.id : id;
      const ref = await collection(getFirestore(), "mailing", mailId, "log");
      await addDoc(ref, {action: action, time: new Date()})
    },
    async loadData(){
      const testDistributor = await this.getTestDistributor()
      this.testDistributor = testDistributor
      // console.log(Object.entries(testDistributor))
      this.working = false
    },
    getTestDistributor(){
      return new Promise((resolve, reject)=>{
        const testDistributorSnap =  query(collection(getFirestore(), "testDistributor"));
        onSnapshot(testDistributorSnap, 
          async (querySnapshot) => {
            let toReturn = {}
            await querySnapshot.forEach(d=>{
              toReturn[d.id] = d.data()
            })
            resolve(toReturn)
          },
        err=>{console.error(err); reject(err)}
        )
      })
    },
    async sendMail(){
      if(this.checkData()){
        this.working = true;
        let addedMailAddresses = this.addedMailAddresses.filter(el=> el.email != '' )
        let objectToSave = {
          prefix: this.prefix ? this.prefix : '',
          testDistributor: this.selectedTestDistibutor?.[0]? this.selectedTestDistibutor[0]: false,
          emailAdresses: addedMailAddresses.length > 0 ? addedMailAddresses : false,
          sender: this.sender,
          uid: this.$store.state.user.data.uid
        }

        this.mailLog(`testversand wurde ausgelöst. ${JSON.stringify(objectToSave.testDistributor)} ${JSON.stringify(objectToSave.emailAdresses)}`)

        await setDoc(doc(getFirestore(), "sendTestMailing", this.$route.params.id), objectToSave)
        this.done = true
        setTimeout(()=>{this.done = false}, 2000);
        this.working = false
        
      } else {
        console.error('Bitte prüfe deine Eingaben.')
        this.errormessage = 'Bitte prüfe deine Eingaben.';
      }
    },
    checkData(){
      let check = true
      !this.selectedTestDistibutor && this.addedMailAddresses[0].email == '' && (check = false)

      this.addedMailAddresses.forEach( (address) => {
        address.customernumber <= 0 && (check = false)
        !address.customernumber && (check = false)
      })

      return check
    },

  },
  mounted(){
    this.loadData()
  },
}
</script>

<style scoped>
  .scrollBox {
    border: 1px #f2f2f2 solid;
    max-height: 900px; 
    overflow-y: scroll;
  }
</style>