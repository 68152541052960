<template>
  <BreadCrumb/>
  <OverView/>
  <div class="">
    <h5 class="text-center mt-5 mb-5">Empfängerliste</h5>
    <div class="container">
      <div class="shadow-none p-3 mb-5 bg-light rounded">
        Hier kann anhand der Kunden- und Personenstichwörtern eine Empfängerliste zusammengestellt werden.
      </div>
    </div>
    <transition name="fade">
      <Loading v-if="working && !done" />
    </transition>
    <transition name="fade">
      <div v-if="done">
        <div class="container">
          <div class="alert alert-success" role="alert">
            <p>Empängerliste wurde erstellt.</p>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="container" v-if="!working && !done">
        <div class="row">
          <div class="col">

            <form class="row g-3" @submit.prevent="saveRecipientList" autocomplete="off">

              <SelectLanguage @selectChanged="selectLanguageChange" :preSelected="languageCode"/>

              <div class="mb-3">
                <label class="form-label">Kundenstichworte [AND]</label><Info class="ms-2" content="Wähle die Kundenstichwörter: [AND] verknüpft Bedeutet, dass ALLE Kunden ALLE der gewählten Stichwörter haben."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
                <SelectDistributor @selectChanged="selectChangedCustomerAND" :preSelected="customerKeywordsAND" get="customerAND"/>
              </div>

              <div class="mb-3">
                <label class="form-label">Kundenstichworte [OR]</label><Info class="ms-2" content="Wähle die Kundenstichwörter: [OR] verknüpft - Bedeutet, dass ALLE Kunden EINES der gewählten Stichwörter haben."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
                <SelectDistributor @selectChanged="selectChangedCustomerOR" :preSelected="customerKeywordsOR" get="customerOR"/>
              </div>

              <div class="mb-3">
                <label class="form-label">Personenstichwörte</label><Info class="ms-2" content="Wähle die Personenstichwörter: Die personenstichwörter sind immer [OR] verknüpft."><font-awesome-icon :icon="['fas', 'question-circle']" /></Info>
                <SelectDistributor @selectChanged="selectChangedPerson" :preSelected="personKeywords" get="person" />
              </div>

              <transition name="fade">
                <div  v-if="errors.length > 0" class="mb-3">
                  <div class="alert alert-danger" role="alert">
                    <ul>
                      <li v-for="error in errors" :key="error">{{error}}</li>
                    </ul>
                  </div>
                </div>
              </transition>

              <div class="col d-flex justify-content-center mb-5">
                <SaveButton :saving="saving" text="Speichern" :done="done"/>
              </div>
            </form>

          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { firebase, region } from "../../plugins/firebase";
import Loading from '../Loading.vue'
import BreadCrumb from '../smallComponents/MailBreadCrumb.vue'
import OverView from '../smallComponents/MailOverview.vue'
import SelectDistributor from "../smallComponents/SelectDistributor.vue"
import SelectLanguage from "../smallComponents/SelectLanguage.vue"
import Info from '../smallComponents/Info.vue'
import SaveButton from "../smallComponents/SaveButton.vue"
import {toRaw} from 'vue'

export default {
  name: 'CreateMailing',
  inject: {
    region: {
      from: '$region'
    },
    firebase: {
      from: '$firebase'
    }
  },
  emits: ['selectDistibutorChanged'],
  components:{
    Loading,
    Info,
    BreadCrumb,
    OverView,
    SelectDistributor,
    SelectLanguage,
    SaveButton
  },
  watch: {
    name: function (val) {
      if(val){
        let index = this.name.indexOf(this.erroMessages.name)
        if (index > -1) {
          this.errors.splice(index, 1);
        }
      }
    },
  },
  data(){
    return {
      name: null,
      customerKeywordsOR: [], 
      customerKeywordsAND: [], 
      personKeywords: [],
      errors: [],
      erroMessages: {
        personkeywords: 'Bitte fülle das Feld Mailingname aus.',
        customerkeywords: 'Bitte wähle einen Verteiler.'
      },
      languageCode: "",
      working: false,
      saving: false,
      done: false,
    }
  },
  methods:{
    async loadData(){
      const docSnap = await getDoc(doc(getFirestore(), "mailing", this.$route.params.id ));
      return new Promise((resolve)=>{
        if (docSnap.exists()) {
            const requestObject = docSnap.data()?.recipientListRequestObject
            this.customerKeywordsOR = requestObject?.customerKeywords?.or || []
            this.customerKeywordsAND = requestObject?.customerKeywords?.and || []
            this.personKeywords = requestObject?.personKeywords?.or || []
            this.languageCode = requestObject?.language || ""
            resolve()
        } else {
          console.log("No such document!");
          resolve()
        }
      })
    },
    selectLanguageChange(data){
      // console.log(data)
      this.languageCode = data
    },
    selectChangedCustomerOR(data){
      // console.log(data)
      this.customerKeywordsOR = data.data
    },
    selectChangedCustomerAND(data){
      // console.log(data.data)
      this.customerKeywordsAND = data.data

    },
    selectChangedPerson(data){
      this.personKeywords = data.data
    },
    async saveRecipientList(){
      this.saving = true,
      console.log('here', toRaw(this.customerKeywordsAND))
      const dataToGetRecipientList = {
        mailingId: this.$route.params.id,
        language: this.languageCode || "",
        customerKeywords: {
            and: toRaw(this.customerKeywordsAND) || [// Jedes Stichwort muss im Kunden existieren
            ],
            or: toRaw(this.customerKeywordsOR) || [// Eins der Stichworte muss im Kunden existieren 
            ] 
        },
        personKeywords: {
            and: [
                "opt-in-mail"
            ],
            or: toRaw(this.personKeywords) || [
            ]
        }
      }

      const docRef = await doc(getFirestore(), "mailing", this.$route.params.id );
      console.log(this.$route.params.id)

      let getRecipientList = await httpsCallable(getFunctions(firebase, region), 'getRecipientList');
      await getRecipientList(dataToGetRecipientList)
        .then(async (data)=>{
          await updateDoc(docRef, {
            recipientListRequestObject: dataToGetRecipientList, 
            recipientListFetchedData: data.data,
            timestamp: new Date()
          })
        this.$router.push({ name: 'Mailing vorschau', params: { id: this.$route.params.id } })
        })
        .catch(err=>{ 
          this.errors.push(err)
          console.error("error here")     
          console.error(err)
        })


    }
  },
  async mounted(){
    await this.loadData()
  },
}
</script>

<style scoped>
  a {
    color: black;
    text-decoration: none;
  }
  .fade-enter-active,
  .fade-leave-active {
    transform: scaleY(1);
  }

  .fade-enter-from,
  .fade-leave-to {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.25s ease;
  }
</style>